/*****************************
* Component - Hero
*****************************/
.hero-wrapper{
    position: relative;
    height: 940px;
    overflow: hidden;
    z-index: 1;
    
    @include breakpoint(small){
        height: 1180px;
    }
    @include breakpoint(medium){
        height: 1200px;
    }

    @include breakpoint(large){
        height: 750px;
    }
    @include breakpoint(xlarge){
        height: 910px;
    }

    @include breakpoint(xxxlarge){
        height: 1130px;
    }
}

.hero-content{
    position: absolute;
    bottom: 60px;
    transform: translateY(0%);

    @include breakpoint(large){
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
    }

    .title-big,
    .tile-large{
        letter-spacing: 0px;
        font-weight: 700;
        line-height: 1;
        color: $white;
    }

    .title-big{
        margin-top: 80px;
        font-size: 36px;
        @include breakpoint(large){
            font-size: 50px;
        }
        @include breakpoint(xlarge){
            font-size: 60px;
        }
        @include breakpoint(xxlarge){
            font-size: 85px;
        }
    }

    .title-large{
        font-size: 60px;
        font-weight: 700;
        @include breakpoint(large){
            font-size: 80px;
        }
        @include breakpoint(xlarge){
            font-size: 90px;
        }

        @include breakpoint(xxlarge){
            font-size: 110px;
        }

        @include breakpoint(xxxlarge){
            font-size: 140px;
        }
    }

    .shape-mark{
        position: relative;
        z-index: 1;

        &::after{
            position: absolute;
            content: "";
            z-index: -1;
            background: url("../../../images/icon/hero-name-mark-shape.png");
            width: 100%;
            height: 22px;
            left: 0;
            bottom: 0;
            background-repeat: no-repeat;
        }
    }

    p{
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 16px; 
       
        @include breakpoint(large){
            margin-top: 40px;
            margin-bottom: 60px;
            font-size: 16px;
            width: 400px;
        }
        @include breakpoint(xlarge){
            font-size: 18px;
            width: 400px;
        }
        @include breakpoint(xxlarge){
            font-size: 18px;
            width: 400px;
        }
        @include breakpoint(xxxlarge){
            font-size: 22px;
            width: 550px;
        }
    }
}

.video-link{
    display: flex;
    align-items: center;
    margin-top: 40px;
    .video-text{padding-left: 30px;}

    @include breakpoint(large){
        margin-top: 40px;
    }
    @include breakpoint(xlarge){
        margin-top: 50px;
    }
    @include breakpoint(xxlarge){
        margin-top: 125px;
    }
    @include breakpoint(xxxlarge){
        margin-top: 125px;
    }
}

.hero-top-shape{
    position: absolute;
    top: -100px;
}

.hero-bottom-shape{
    position: absolute;
    bottom: -100px;
}

.hero-shape{
    z-index: -1;
    span{
        position: absolute;
        border: 1px solid rgba($white, .1);
        border-radius: 50%;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        visibility: hidden;
        opacity: 0;

        @include breakpoint(xlarge){
            visibility: visible;
            opacity: 1;
        }
    }

    
    span:nth-child(1){
        width: 440px;
        height: 440px;
    }
    span:nth-child(2){
        width: 560px;
        height: 560px;
    }
    span:nth-child(3){
        width: 700px;
        height: 700px;
    }
}

.hero-portrait{
    position: absolute;
    left: calc(50% - 22px);
    transform: translateX(-50%);
    top: 130px;
    width: 240px;
    z-index: -1;
    @include breakpoint(small){
        left: calc(50% - 36px);
        width: 400px;
        top: 150px;
    }

    @include breakpoint(large){
        right: 0px;
        width: 400px;
        bottom: 0;
        top: auto;
        left: auto;
    }
    @include breakpoint(xlarge){
        right: -155px;
        width: auto;
    }
    @include breakpoint(xxxlarge){
        right: 0;
    }
    .image{
        height: auto;
        position: relative;
        z-index: 8;

        @include breakpoint(large){
            height: 570px;
        }
        @include breakpoint(xlarge){
            height: 700px;
        }
        @include breakpoint(xxxlarge){
            height: 950px;
        }
    }

    .image-half-round-shape{
        bottom: 10%;
        left: 50%;
        position: absolute;
        width: 150px;
        height: 250px;
        border-top-right-radius: 150px;
        border-bottom-right-radius: 150px;
        border: 1px solid rgba($white, .25);
        border-left: 0;
        z-index: -1;

        @include breakpoint(small){
            width: 260px;
            height: 400px;
            border-top-right-radius: 200px;
            border-bottom-right-radius: 200px;
        }

        @include breakpoint(large){
            bottom: 0%;
            left: 60%;
            width: 260px;
            height: 464px;
            border-top-right-radius: 250px;
            border-bottom-right-radius: 250px;
        }
        @include breakpoint(xlarge){
            width: 350px;
            height: 600px;
            border-top-right-radius: 350px;
            border-bottom-right-radius: 350px;
        }
        @include breakpoint(xxxlarge){
            width: 400px;
            height: 800px;
            border-top-right-radius: 400px;
            border-bottom-right-radius: 400px;
        }

        &::before{
            bottom: 68px;
            left: -75px;
            position: absolute;
            content: "";
            width: 400px;
            height: 660px;
            border-top-right-radius: 400px;
            border-bottom-right-radius: 400px;
            border: 1px solid rgba($white, .05);
            border-left: 0;
            z-index: -1;
            visibility: hidden;
            opacity: 0;

            @include breakpoint(large){
                visibility: visible;
                opacity: 1;
                bottom: 50px;
                left: 0;
                width: 220px;
                height: 370px;
                border-top-right-radius: 220px;
                border-bottom-right-radius: 220px;
               
            }
            @include breakpoint(xlarge){
                bottom: 50px;
                left: 0;
                width: 300px;
                height: 500px;
                border-top-right-radius: 350px;
                border-bottom-right-radius: 350px;
            }

            @include breakpoint(xxxlarge){
                bottom: 68px;
                left: -75px;   
                width: 400px;
                height: 660px;
                border-top-right-radius: 400px;
                border-bottom-right-radius: 400px;
            }
        }
        &::after{
            bottom: -46px;
            left: 24px;
            position: absolute;
            content: "";
            width: 450px;
            height: 900px;
            border-top-right-radius: 450px;
            border-bottom-right-radius: 450px;
            border: 1px solid rgba($white, .05);
            border-left: 0;
            z-index: -1;
            visibility: hidden;
            opacity: 0;

            @include breakpoint(large){
                visibility: visible;
                opacity: 1;
                bottom: -28px;
                left: -69px;
                width: 396px;
                height: 540px;
                border-top-right-radius: 340px;
                border-bottom-right-radius: 340px;
                
            }
            @include breakpoint(xlarge){
                bottom: -40px;
                left: -64px;
                width: 478px;
                height: 693px;
                border-top-right-radius: 400px;
                border-bottom-right-radius: 400px;
            }

            @include breakpoint(xxxlarge){
                bottom: -46px;
                left: 24px;
                width: 450px;
                height: 900px;
                border-top-right-radius: 450px;
                border-bottom-right-radius: 450px;
            }
        }
    }

    .social-link{
        a{
            display: block;
            text-align: center;
            color: $white;
            width: 35px;
            height: 35px;
            line-height: 35px;
            font-size: 16px;
            border-radius: 50%;
            border: 1px solid $white;
            z-index: 11;
            position: absolute;
            background: $color-dark-blue;
            
            @include breakpoint(small){
                width: 45px;
                height: 45px;
                line-height: 45px;
            }
            @include breakpoint(medium){
                font-size: 20px;
                width: 45px;
                height: 45px;
                line-height: 45px;
            }
            @include breakpoint(large){
                font-size: 20px;
                width: 60px;
                height: 60px;
                line-height: 60px;
            }
            @include breakpoint(xlarge){
                width: 60px;
                height: 60px;
                line-height: 60px;
            }
            @include breakpoint(xxxlarge){
                width: 80px;
                height: 80px;
                line-height: 80px;
            }

            &:hover{
                color: $color-dark-blue;
                background: $white;
            }

            &:nth-child(1){
                left: 175px;
                top: 50px;

                @include breakpoint(small){
                    left: 315px;
                    top: 110px;
                }

                @include breakpoint(large){
                    left: 335px;
                    top: 96px;
                }

                @include breakpoint(xlarge){
                    left: 535px;
                    top: 96px;
                }
                @include breakpoint(xxxlarge){
                    left: 590px;
                    top: 185px;
                }
            }
            &:nth-child(2){
                left: 225px;
                top: 90px;

                @include breakpoint(small){
                    left: 395px;
                    top: 170px;
                }

                @include breakpoint(large){
                    left: 430px;
                    top: 188px;
                }
                @include breakpoint(xlarge){
                    left: 655px;
                    top: 188px;
                }
                @include breakpoint(xxxlarge){
                    left: 715px;
                    top: 336px;
                }
            }
            &:nth-child(3){
                left: 250px;
                top: 150px;

                @include breakpoint(small){
                    left: 435px;
                    top: 255px;
                }

                @include breakpoint(large){
                    left: 470px;
                    top: 325px;
                }
                @include breakpoint(xlarge){
                    left: 715px;
                    top: 325px;
                }
                @include breakpoint(xxxlarge){
                    left: 758px;
                    top: 540px;
                }
            }
            &:nth-child(4){
                left: 247px;
                top: 213px;

                @include breakpoint(small){
                    left: 430px;
                    top: 348px;
                }

                @include breakpoint(large){
                    left: 400px;
                    top: 480px;
                }
                @include breakpoint(xlarge){
                    left: 700px;
                    top: 480px;
                }
                @include breakpoint(xxxlarge){
                    left: 687px;
                    top: 740px;
                }
            }
        }
    }
}