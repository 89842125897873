@import "../variables";
@import "../mixins";

.content-hero-image{
    margin-bottom: 65px;
}

.content-meta{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: -10px;
    .post-meta-2{
        margin-bottom: 0;
    }
    & > *{
        margin-bottom: 10px;
    }
}

.default-content-style{
    .title{
        font-size: 34px;
        font-weight: 700;
        line-height: 1.02;
        margin-bottom: 50px;
        margin-top: 25px;
        @include breakpoint(small){
            font-size: 38px;
        }
        @include breakpoint(medium){
            font-size: 48px;
        }
        @include breakpoint(large){
            font-size: 55px;
        }
        @include breakpoint(xlarge){
            font-size: 66px;
        }
    }

    .sub-title{
        font-size: 30px;
        font-weight: 600;
        margin-top: 60px;
        margin-bottom: 30px;

        @include breakpoint(small){
            font-size: 36px;
        }
        @include breakpoint(medium){
            font-size: 38px;
        }
        @include breakpoint(large){
            font-size: 45px;
        }
        @include breakpoint(xlarge){
            font-size: 50px;
        }
    }

    .quote{
        font-size: 23px;
        line-height: 1.2;
        text-decoration: underline;
        font-weight: 600;
        color: #9899a1;
        margin-bottom: 25px;
    }

    p{
        margin-bottom: 25px;
        &:last-child{
            margin-bottom: 0;
        }
    }

    .content-list-item{
        li{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .list-title{
            font-family: $body-font;
            font-size: 24px;

            @include breakpoint(medium){
             font-size: 28px
            }
        }

        .icon{
            font-size: 35px;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid rgba($white, .1);
            color: $white;
        }
    }

    .content-list-with-text{
        li{
            align-items: center;
            margin-bottom: 30px;
            @include breakpoint(medium){
                flex-direction: row;
            }
            .left{
                width: 100%;
                word-break: break-all;
                margin-right: 0px;
                margin-bottom: 10px;
                @include breakpoint(medium){
                    width: 190px;
                    margin-right: 50px;
                    margin-bottom: 0;
                }
                @include breakpoint(large){
                    margin-right: 70px;
                }
                
            }
            .right{
                width: 100%;
                @include breakpoint(medium){
                    width: calc(100% - 220px);
                }
                @include breakpoint(large){
                    width: calc(100% - 260px);
                }
                
            }

            .list-title{
                font-family: $body-font;
                font-size: 24px;

                @include breakpoint(medium){
                 font-size: 26px
                }
                @include breakpoint(large){
                 font-size: 28px
                }
            }

           

        }
    }
    .content-list-with-icon{
        margin-top: 60px;
        li{
            align-items: flex-start;
            margin-bottom: 45px;
            @include breakpoint(medium){
                flex-direction: row;
            }
            .left{
                width: 100%;
                word-break: break-all;
                margin-right: 0px;
                margin-bottom: 25px;
                @include breakpoint(medium){
                    margin-right: 50px;
                    margin-bottom: 0;
                    width: 100px;
                }
                @include breakpoint(large){
                    margin-right: 40px;
                }
            }
            .right{
                width: 100%;
                @include breakpoint(medium){
                    width: calc(100% - 40px);
                }
                @include breakpoint(large){
                    width: calc(100% - 40px);
                }
                
            }


            .list-title{
                margin-bottom: 25px;
            }

        }
    }

    .content-inner-image{
        overflow: hidden;
        &.image-double{
            display: flex;
            flex-direction: column;
            @include breakpoint(small){
                flex-direction: row;
            }
            .image{
                width: 100%;
                height: 420px;
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 30px;
                }
                @include breakpoint(small){
                    width: calc(50% - 30px);
                    margin-right: 30px;
                    margin-bottom: 0;
                    height: 300px;
                }

                @include breakpoint(medium){
                    height: 420px;
                }
                &:last-child{
                    margin-right: 0;
                }

                img{
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}