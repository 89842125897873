/************************************
* Component - Pagination
************************************/
.pagination-nav-list{
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    margin-bottom: -20px;

    li{
        margin-right: 20px;
        margin-bottom: 20px;
        &:last-child{
            margin-right: 0;
        }

        &.active a{
            background: $color-dark-blue;
            border-color: $color-dark-blue;
        }
    }
    a{
        width: 45px;
        height: 45px;
        border: 1px solid rgba($white, .1);
        line-height: 45px;
        text-align: center;
        display: inline-block;
        @include breakpoint(medium){
            width: 65px;
            height: 65px;
            line-height: 65px;
        }
        &:hover{
            background: $color-dark-blue;
            border-color: $color-dark-blue;
        }
    }
}