/************************************
* Pages - Blog
************************************/

@import "blog/blog-list";
@import "blog/blog-details";

.post-meta-1{
    a{
        font-weight: 300;
        margin-right: 35px;
        position: relative;

        &::after{
            position: absolute;
            content: "-";
            left: calc(100% + 17.5px);
        }
        &:last-child{
            margin-right: 0;
            &::after{
                display: none;
            }
        }
    }
    .catagory{
        color: $white;
    }
}

.post-meta-2{
    margin-bottom: -10px;
    a,span{
        display: inline-block;
        margin-bottom: 10px;
        margin-right: 15px;
        &:last-child{margin-right: 0;}

        @include breakpoint(medium){
            margin-right: 30px;
        }
        @include breakpoint(large){
            margin-right: 20px;
        }
        @include breakpoint(xlarge){
            margin-right: 45px;
        }
    }
}