/************************************
* Component - Solution Display
************************************/

.solution-display-wrapper{

    padding: 0 30px;
    @include breakpoint(small){
        padding: 0 50px;
    } 
    @include breakpoint(xxxlarge){
        padding: 0;
    } 
    .swiper-wrapper{
        @include breakpoint(xxxlarge){
            left: -270px;
        } 
    }

    .solution-box-single-item{
    
        @include breakpoint(xxxlarge){
            width: 735px !important;
        }
    }
}

.solution-box-single-item{
    
    .img-box{
        overflow: hidden;
        padding: 30px 30px 0 30px;
        height: 300px;
        
        position: relative;
        z-index: 1;

        @include breakpoint(large){
            padding: 50px 50px 0 50px;
            height: 370px;
        }

        @include breakpoint(large){
            padding: 50px 50px 0 50px;
            height: 240px;
        }
        @include breakpoint(large){
            padding: 50px 50px 0 50px;
            height: 335px;
        }
        @include breakpoint(xlarge){
            padding: 100px 100px 0 100px;
            height: 450px;
        }
        @include breakpoint(xxlarge){
            padding: 100px 100px 0 100px;
            height: 548px;
        }
        .bg-overlay{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($color-dark-blue, .95);
        }
        .bg-image{
            position: absolute;
            transform: translate(-50%, -50%) rotate(-45deg);
            top: 50%;
            left: 50%;
            z-index: -1;
        }
     
        .image{
            position: relative;
             z-index: 1;
             img{
                 width: 100%;
             }
        }
    }


    .title{
        font-family: $body-font;
        font-size: 22px;
        font-weight: 500;
        margin-top: 40px;
        margin-bottom: 16px;
        @include breakpoint(medium){
            font-size: 28px;
            margin-top: 55px;
            margin-bottom: 25px;
        }
        a{
            color: $white;
        }
    }

    .catagory-nav-item{
        font-weight: 300;
        
        li{
            display: inline-block;
            position: relative;
           
            margin-right: 10px;
            &::after{
                position: absolute;
                content: "/";
                left: calc(100% + 5px);
            }

            &:last-child::after{
                display: none;
            }
        }
    }
}