/************************************
* Component - Breadcrumb
************************************/
.breadcrumb-box{
    padding-top: 130px;
    padding-bottom: 45px;

    @include breakpoint(medium){
        padding-top: 175px;
        padding-bottom: 90px;
    }
    @include breakpoint(large){
        padding-top: 175px;
        padding-bottom: 110px;
    }
    @include breakpoint(xxlarge){
        padding-top: 225px;
        padding-bottom: 130px;
    }
    @include breakpoint(xxxlarge){
        padding-top: 275px;
        padding-bottom: 180px;
    }
}

.breadcrumb-content{
    text-align: center;
    .title{
        font-size: 40px;
        font-weight: 600;

        @include breakpoint(medium){
            font-size: 50px;
        }
        @include breakpoint(large){
            font-size: 75px;
        }
        @include breakpoint(xxxlarge){
            font-size: 90px;
        }
    }
}

.breadcrumb-link{
    margin-top: 20px;
    padding: 20px 40px;
    border-radius: 30px;
    background: $color-gray-blue;
    display: inline-flex;
    li{
        position: relative;
        margin-right: 30px;
        font-weight: 300;
        font-size: 14px;
        @include  breakpoint(large){
          font-size: 16px;
        };
        &::after{
            position: absolute;
            content: "||";
            left: calc(100% + 10px);
            visibility: visible;
        }
        &:last-child{
            margin-right: 0;
            &::after{
                visibility: hidden;
            }
        }

        &.active{
            color: $white;
        }
    }
}

.breadcrumb-shape-top-left{
    position: absolute;
    width: 800px;
    height: 800px;
    border: 1px solid rgba($white, .1);
    border-radius: 50%;
    top: -375px;
    left: -585px;
    visibility: hidden;
    opacity: 0;

    @include breakpoint(xlarge){
        visibility: visible;
        opacity: 1;
        top: -475px;
        left: -590px;
    }

    @include breakpoint(xxxlarge){
        top: -425px;
        left: -595px;
    }

    &::before{
        position: absolute;
        content: "";
        width: 935px;
        height: 935px;
        border: 1px solid rgba($white, .1);
        border-radius: 50%;
        top: -70px;
        left: -70px;
    }
    &::after{
        position: absolute;
        content: "";
        width: 1065px;
        height: 1065px;
        border: 1px solid rgba($white, .1);
        border-radius: 50%;
        top: -140px;
        left: -140px;
    }
}

.breadcrumb-shape-bottom-right{
    position: absolute;
    width: 800px;
    height: 800px;
    border: 1px solid rgba($white, .1);
    border-radius: 50%;
    bottom: -375px;
    right: -585px;
    visibility: hidden;
    opacity: 0;

    @include breakpoint(xlarge){
        visibility: visible;
        opacity: 1;
        bottom: -475px;
        right: -590px;
    }

    @include breakpoint(xxxlarge){
        bottom: -425px;
        right: -595px;
    }

    &::before{
        position: absolute;
        content: "";
        width: 935px;
        height: 935px;
        border: 1px solid rgba($white, .1);
        border-radius: 50%;
        bottom: -70px;
        right: -70px;
    }
    &::after{
        position: absolute;
        content: "";
        width: 1065px;
        height: 1065px;
        border: 1px solid rgba($white, .1);
        border-radius: 50%;
        bottom: -140px;
        right: -140px;
    }
}
