/************************************
* Component - Tag box
************************************/

.tag-box{
    background: $color-dark-blue;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px 50px;
    
    & > *{
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }

        @include breakpoint(medium){
            margin-bottom: 0;
        }
    }

    .tag-list{
        .title{
            font-family: $body-font;
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 15px;
        }

        .list-item{
            display: flex;
            flex-wrap: wrap;

            li{
                position: relative;
                margin-right: 10px;

                &:after{
                    position: absolute;
                    content: ",";
                    left: calc(100% + 0px);
                }

                &:last-child{
                    margin-right: 0px;
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }
}