@charset "UTF-8";
/*-----------------------------------------------------------------------------------

    Template Name: Lendix
    Template Description: Lendex - Personal Portfolio Bootstrap Template
    Version: 1.0
    Template Author: Hasthemes
    Template URL: https://hasthemes.com/

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    -> Base - Default CSS

    -> Component - Slider
    -> Component - Hero
    -> Component - Service Display
    -> Component - Skill Progress Display
    -> Component - Counterup Display
    -> Component - Solution Display
    -> Component - Testimonial Display
    -> Component - Company Logo Display
    -> Component - Blog Feed Display
    -> Component - Video Card Display
    -> Component - Resume Display
    -> Component - Breadcrumb
    -> Component - Sidebar Widgets
    -> Component - Pricing Area
    -> Component - Pagination
    -> Component - Tag box
    -> Component - Comment


    -> Pages - Solution
    -> Pages - Blog
    -> Pages - FAQ
    -> Pages - Contact
    -> Pages - 404 Error


    -> Layout - Header
    -> Layout - Footer

-----------------------------------------------------------------------------------*/
/*****************************
* Base - Default CSS
*****************************/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* Font Family

font-family: 'Cormorant Garamond', serif;
font-family: 'Poppins', sans-serif;

*/
/*-- Common Style --*/
html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  background: #2a2c39;
  color: #858792;
  font-family: "Poppins", sans-serif;
}

a {
  color: #858792;
  text-decoration: none;
}
a:hover {
  color: #fff;
}

a,
button,
.btn,
img,
input {
  transition: all 0.5s ease;
}

a,
a:hover,
a:focus,
a:active,
button,
button:focus,
.btn,
.btn:focus,
input,
input:focus,
select,
textarea,
.swiper-pagination-bullet,
.swiper-slide,
.append-button,
.swiper-button-next,
.swiper-button-prev {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.btn:active:focus {
  box-shadow: none;
}
.btn-primary:focus {
  border-color: #0d6efd;
  color: #fff;
}

.accordion-button:focus {
  box-shadow: none;
}

h1, h2, h3, h4, h5, h6 {
  color: #fff;
  font-family: "Cormorant Garamond", serif;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

input, textarea {
  width: 100%;
}

p:last-child {
  margin-bottom: 0;
}

.img-full {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center center;
}

::selection {
  background-color: #0d6efd;
  color: #fff;
}

.section-dark-blue-bg {
  background: #252734;
}

.section-gray-blue-bg {
  background: #2a2c39;
}

.social-link {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;
}
.social-link li {
  margin-right: 20px;
  margin-bottom: 20px;
}
.social-link li:last-child {
  margin-right: 0;
}
.social-link a {
  display: block;
  color: #fff;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  line-height: 45px;
  text-align: center;
}
.social-link a:hover {
  background: #fff;
  border-color: #fff;
  color: #252734;
}
@media (min-width: 768px) {
  .social-link a {
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
}

.content-hero-image {
  margin-bottom: 65px;
}

.content-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: -10px;
}
.content-meta .post-meta-2 {
  margin-bottom: 0;
}
.content-meta > * {
  margin-bottom: 10px;
}

.default-content-style .title {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.02;
  margin-bottom: 50px;
  margin-top: 25px;
}
@media (min-width: 576px) {
  .default-content-style .title {
    font-size: 38px;
  }
}
@media (min-width: 768px) {
  .default-content-style .title {
    font-size: 48px;
  }
}
@media (min-width: 992px) {
  .default-content-style .title {
    font-size: 55px;
  }
}
@media (min-width: 1200px) {
  .default-content-style .title {
    font-size: 66px;
  }
}
.default-content-style .sub-title {
  font-size: 30px;
  font-weight: 600;
  margin-top: 60px;
  margin-bottom: 30px;
}
@media (min-width: 576px) {
  .default-content-style .sub-title {
    font-size: 36px;
  }
}
@media (min-width: 768px) {
  .default-content-style .sub-title {
    font-size: 38px;
  }
}
@media (min-width: 992px) {
  .default-content-style .sub-title {
    font-size: 45px;
  }
}
@media (min-width: 1200px) {
  .default-content-style .sub-title {
    font-size: 50px;
  }
}
.default-content-style .quote {
  font-size: 23px;
  line-height: 1.2;
  text-decoration: underline;
  font-weight: 600;
  color: #9899a1;
  margin-bottom: 25px;
}
.default-content-style p {
  margin-bottom: 25px;
}
.default-content-style p:last-child {
  margin-bottom: 0;
}
.default-content-style .content-list-item li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.default-content-style .content-list-item .list-title {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
}
@media (min-width: 768px) {
  .default-content-style .content-list-item .list-title {
    font-size: 28px;
  }
}
.default-content-style .content-list-item .icon {
  font-size: 35px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
}
.default-content-style .content-list-with-text li {
  align-items: center;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .default-content-style .content-list-with-text li {
    flex-direction: row;
  }
}
.default-content-style .content-list-with-text li .left {
  width: 100%;
  word-break: break-all;
  margin-right: 0px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .default-content-style .content-list-with-text li .left {
    width: 190px;
    margin-right: 50px;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .default-content-style .content-list-with-text li .left {
    margin-right: 70px;
  }
}
.default-content-style .content-list-with-text li .right {
  width: 100%;
}
@media (min-width: 768px) {
  .default-content-style .content-list-with-text li .right {
    width: calc(100% - 220px);
  }
}
@media (min-width: 992px) {
  .default-content-style .content-list-with-text li .right {
    width: calc(100% - 260px);
  }
}
.default-content-style .content-list-with-text li .list-title {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
}
@media (min-width: 768px) {
  .default-content-style .content-list-with-text li .list-title {
    font-size: 26px;
  }
}
@media (min-width: 992px) {
  .default-content-style .content-list-with-text li .list-title {
    font-size: 28px;
  }
}
.default-content-style .content-list-with-icon {
  margin-top: 60px;
}
.default-content-style .content-list-with-icon li {
  align-items: flex-start;
  margin-bottom: 45px;
}
@media (min-width: 768px) {
  .default-content-style .content-list-with-icon li {
    flex-direction: row;
  }
}
.default-content-style .content-list-with-icon li .left {
  width: 100%;
  word-break: break-all;
  margin-right: 0px;
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .default-content-style .content-list-with-icon li .left {
    margin-right: 50px;
    margin-bottom: 0;
    width: 100px;
  }
}
@media (min-width: 992px) {
  .default-content-style .content-list-with-icon li .left {
    margin-right: 40px;
  }
}
.default-content-style .content-list-with-icon li .right {
  width: 100%;
}
@media (min-width: 768px) {
  .default-content-style .content-list-with-icon li .right {
    width: calc(100% - 40px);
  }
}
@media (min-width: 992px) {
  .default-content-style .content-list-with-icon li .right {
    width: calc(100% - 40px);
  }
}
.default-content-style .content-list-with-icon li .list-title {
  margin-bottom: 25px;
}
.default-content-style .content-inner-image {
  overflow: hidden;
}
.default-content-style .content-inner-image.image-double {
  display: flex;
  flex-direction: column;
}
@media (min-width: 576px) {
  .default-content-style .content-inner-image.image-double {
    flex-direction: row;
  }
}
.default-content-style .content-inner-image.image-double .image {
  width: 100%;
  height: 420px;
  margin-bottom: 30px;
}
.default-content-style .content-inner-image.image-double .image:last-child {
  margin-bottom: 30px;
}
@media (min-width: 576px) {
  .default-content-style .content-inner-image.image-double .image {
    width: calc(50% - 30px);
    margin-right: 30px;
    margin-bottom: 0;
    height: 300px;
  }
}
@media (min-width: 768px) {
  .default-content-style .content-inner-image.image-double .image {
    height: 420px;
  }
}
.default-content-style .content-inner-image.image-double .image:last-child {
  margin-right: 0;
}
.default-content-style .content-inner-image.image-double .image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.btn {
  font-size: 15px;
  text-align: center;
  position: relative;
  z-index: 9;
  overflow: hidden;
  color: #fff;
  box-shadow: none !important;
  outline: none !important;
}
.btn:hover {
  color: #fff;
}
.btn-sm {
  padding-left: 40px;
  padding-right: 40px;
  height: 65px;
  border-radius: 32.5px;
  line-height: 60px;
}
.btn-sm::after {
  content: "";
  border-radius: 32.5px;
}
.btn-md {
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  border-radius: 35px;
  line-height: 35px;
}
@media (min-width: 576px) {
  .btn-md {
    padding-left: 40px;
    padding-right: 40px;
    height: 60px;
    line-height: 50px;
  }
}
@media (min-width: 1200px) {
  .btn-md {
    padding-left: 50px;
    padding-right: 50px;
    height: 70px;
    line-height: 60px;
  }
}
.btn-md::after {
  content: "";
  border-radius: 35px;
}
.btn-lg {
  border-radius: 32.5px;
  padding-left: 30px;
  padding-right: 30px;
  height: 50px;
  line-height: 30px;
}
@media (min-width: 576px) {
  .btn-lg {
    padding-left: 40px;
    padding-right: 40px;
    height: 60px;
    line-height: 43px;
  }
}
@media (min-width: 1200px) {
  .btn-lg {
    padding-left: 60px;
    padding-right: 60px;
    height: 65px;
    line-height: 45px;
  }
}
.btn-lg::after {
  content: "";
  border-radius: 32.5px;
}
.btn-xl {
  padding-left: 30px;
  padding-right: 30px;
  height: 60px;
  border-radius: 42.5px;
  line-height: 45px;
}
@media (min-width: 576px) {
  .btn-xl {
    padding-left: 30px;
    padding-right: 30px;
    height: 55px;
    line-height: 44px;
  }
}
@media (min-width: 768px) {
  .btn-xl {
    padding-left: 40px;
    padding-right: 40px;
    height: 60px;
    line-height: 50px;
  }
}
@media (min-width: 1200px) {
  .btn-xl {
    padding-left: 60px;
    padding-right: 60px;
    height: 85px;
    line-height: 70px;
  }
}
.btn-xl::after {
  content: "";
  border-radius: 42.5px;
}
.btn-fill-one {
  background: #2e303d;
}
.btn-fill-one::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleX(0.3);
  width: 100%;
  height: 100%;
  background: transparent;
  transition: all 0.5s ease;
  z-index: -1;
}
.btn-fill-one:hover {
  border: 1px solid #3b3d49;
}
.btn-fill-one:hover::after {
  transform: translate(-50%, -50%) scaleX(1.2);
  background: #3b3d49;
}
.btn-outline-one {
  border: 1px solid #3b3d49;
  transition: all 0.5s ease;
}
.btn-outline-one::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleX(0.8);
  width: 100%;
  height: 100%;
  background: transparent;
  transition: all 0.5s ease;
  z-index: -1;
}
.btn-outline-one:hover {
  border: 1px solid #2e303d;
}
.btn-outline-one:hover::after {
  transform: translate(-50%, -50%) scaleX(1.2);
  background: #2e303d;
}

@keyframes animate-ripple {
  50% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
.wave-btn {
  display: block;
  position: relative;
  width: 60px;
  height: 60px;
  background: #2a2c39;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 992px) {
  .wave-btn {
    width: 100px;
    height: 100px;
  }
}

.ripple, .ripple::before, .ripple::after {
  position: absolute;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
  animation: animate-ripple 3s infinite linear backwards;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.ripple::before {
  position: absolute;
  content: "";
  animation-delay: 0.5s;
}

.ripple::after {
  position: absolute;
  content: "";
  animation-delay: 0.9s;
}

/*Customize Bootstrap Container*/
@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1170px;
  }
}
/*Icon Space*/
.icon-space-left i {
  padding-left: 10px;
}

.icon-space-right i {
  padding-right: 10px;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.inner-shape {
  transform: scale(0.85);
  transition: all 0.5s ease;
  visibility: hidden;
  opacity: 0;
}

.inner-shape-top-right {
  position: absolute;
  width: 345px;
  height: 345px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  top: -230px;
  right: -230px;
}
.inner-shape-top-right::before {
  position: absolute;
  content: "";
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  top: -20px;
  right: -20px;
}
.inner-shape-top-right::after {
  position: absolute;
  content: "";
  width: 460px;
  height: 460px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  top: -40px;
  right: -40px;
}

/*Default Inner Shape*/
.inner-shape-bottom-right {
  position: absolute;
  width: 345px;
  height: 345px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  bottom: -230px;
  right: -230px;
}
.inner-shape-bottom-right::before {
  position: absolute;
  content: "";
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  bottom: -20px;
  right: -20px;
}
.inner-shape-bottom-right::after {
  position: absolute;
  content: "";
  width: 460px;
  height: 460px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  bottom: -40px;
  right: -40px;
}

.mb-n5 {
  margin-bottom: -3rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.mb-n6 {
  margin-bottom: -25px;
}

.mb-6 {
  margin-bottom: 25px;
}

.section-bg {
  background: #252734;
}

.section-bg-2 {
  background: #282a37;
}

.section-mt-75 {
  margin-top: 45px;
}
@media (min-width: 992px) {
  .section-mt-75 {
    margin-top: 65px;
  }
}

.section-mt-165 {
  margin-top: 75px;
}
@media (min-width: 992px) {
  .section-mt-165 {
    margin-top: 115px;
  }
}
@media (min-width: 1400px) {
  .section-mt-165 {
    margin-top: 135px;
  }
}
@media (min-width: 1800px) {
  .section-mt-165 {
    margin-top: 160px;
  }
}

.section-gap-t-165 {
  padding-top: 75px;
}
@media (min-width: 992px) {
  .section-gap-t-165 {
    padding-top: 115px;
  }
}
@media (min-width: 1400px) {
  .section-gap-t-165 {
    padding-top: 135px;
  }
}
@media (min-width: 1800px) {
  .section-gap-t-165 {
    padding-top: 160px;
  }
}

.section-gap-tb-165 {
  padding-top: 75px;
  padding-bottom: 75px;
}
@media (min-width: 992px) {
  .section-gap-tb-165 {
    padding-top: 115px;
    padding-bottom: 115px;
  }
}
@media (min-width: 1400px) {
  .section-gap-tb-165 {
    padding-top: 135px;
    padding-bottom: 135px;
  }
}
@media (min-width: 1800px) {
  .section-gap-tb-165 {
    padding-top: 160px;
    padding-bottom: 160px;
  }
}

.section-content {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .section-content {
    margin-bottom: 60px;
  }
}
@media (min-width: 1400px) {
  .section-content {
    margin-bottom: 100px;
  }
}

.section-tag {
  font-size: 18px;
  font-weight: 300;
  position: relative;
  display: inline-block;
  padding-left: 27px;
}
.section-tag::before {
  position: absolute;
  content: "||";
  top: 0;
  left: 0;
}

.section-title {
  font-size: 36px;
  font-weight: 700;
  margin-top: 25px;
}
@media (min-width: 576px) {
  .section-title {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .section-title {
    font-size: 50px;
  }
}
@media (min-width: 992px) {
  .section-title {
    font-size: 70px;
  }
}

.default-form-group input, .default-form-group textarea {
  background: #2a2c39;
  padding: 12px 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  transition: all 0.3s ease;
}
.default-form-group input:focus, .default-form-group textarea:focus {
  border-color: #fff;
}
@media (min-width: 576px) {
  .default-form-group input, .default-form-group textarea {
    padding: 20px 25px;
  }
}
.default-form-group textarea {
  min-height: 180px;
}
@media (min-width: 768px) {
  .default-form-group textarea {
    min-height: 240px;
  }
}
.default-form-group .btn {
  margin-top: 20px;
}

/*****************************
* Component - Slider
*****************************/
.default-nav-style {
  display: flex;
}
.default-nav-style .slider-button {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-right: 20px;
  transition: all 0.5s ease;
  outline: none;
}
@media (min-width: 768px) {
  .default-nav-style .slider-button {
    width: 55px;
    height: 55px;
  }
}
@media (min-width: 992px) {
  .default-nav-style .slider-button {
    width: 65px;
    height: 65px;
  }
}
@media (min-width: 1200px) {
  .default-nav-style .slider-button {
    width: 85px;
    height: 85px;
  }
}
.default-nav-style .slider-button:last-child {
  margin-right: 0;
}
.default-nav-style .slider-button:hover {
  background: #fff;
  color: #212529;
}

/*****************************
* Component - Hero
*****************************/
.hero-wrapper {
  position: relative;
  height: 940px;
  overflow: hidden;
  z-index: 1;
}
@media (min-width: 576px) {
  .hero-wrapper {
    height: 1180px;
  }
}
@media (min-width: 768px) {
  .hero-wrapper {
    height: 1200px;
  }
}
@media (min-width: 992px) {
  .hero-wrapper {
    height: 750px;
  }
}
@media (min-width: 1200px) {
  .hero-wrapper {
    height: 910px;
  }
}
@media (min-width: 1800px) {
  .hero-wrapper {
    height: 1130px;
  }
}

.hero-content {
  position: absolute;
  bottom: 60px;
  transform: translateY(0%);
}
@media (min-width: 992px) {
  .hero-content {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}
.hero-content .title-big,
.hero-content .tile-large {
  letter-spacing: 0px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
}
.hero-content .title-big {
  margin-top: 80px;
  font-size: 36px;
}
@media (min-width: 992px) {
  .hero-content .title-big {
    font-size: 50px;
  }
}
@media (min-width: 1200px) {
  .hero-content .title-big {
    font-size: 60px;
  }
}
@media (min-width: 1400px) {
  .hero-content .title-big {
    font-size: 85px;
  }
}
.hero-content .title-large {
  font-size: 60px;
  font-weight: 700;
}
@media (min-width: 992px) {
  .hero-content .title-large {
    font-size: 80px;
  }
}
@media (min-width: 1200px) {
  .hero-content .title-large {
    font-size: 90px;
  }
}
@media (min-width: 1400px) {
  .hero-content .title-large {
    font-size: 110px;
  }
}
@media (min-width: 1800px) {
  .hero-content .title-large {
    font-size: 140px;
  }
}
.hero-content .shape-mark {
  position: relative;
  z-index: 1;
}
.hero-content .shape-mark::after {
  position: absolute;
  content: "";
  z-index: -1;
  background: url("../../images/icon/hero-name-mark-shape.png");
  width: 100%;
  height: 22px;
  left: 0;
  bottom: 0;
  background-repeat: no-repeat;
}
.hero-content p {
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 16px;
}
@media (min-width: 992px) {
  .hero-content p {
    margin-top: 40px;
    margin-bottom: 60px;
    font-size: 16px;
    width: 400px;
  }
}
@media (min-width: 1200px) {
  .hero-content p {
    font-size: 18px;
    width: 400px;
  }
}
@media (min-width: 1400px) {
  .hero-content p {
    font-size: 18px;
    width: 400px;
  }
}
@media (min-width: 1800px) {
  .hero-content p {
    font-size: 22px;
    width: 550px;
  }
}

.video-link {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.video-link .video-text {
  padding-left: 30px;
}
@media (min-width: 992px) {
  .video-link {
    margin-top: 40px;
  }
}
@media (min-width: 1200px) {
  .video-link {
    margin-top: 50px;
  }
}
@media (min-width: 1400px) {
  .video-link {
    margin-top: 125px;
  }
}
@media (min-width: 1800px) {
  .video-link {
    margin-top: 125px;
  }
}

.hero-top-shape {
  position: absolute;
  top: -100px;
}

.hero-bottom-shape {
  position: absolute;
  bottom: -100px;
}

.hero-shape {
  z-index: -1;
}
.hero-shape span {
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
}
@media (min-width: 1200px) {
  .hero-shape span {
    visibility: visible;
    opacity: 1;
  }
}
.hero-shape span:nth-child(1) {
  width: 440px;
  height: 440px;
}
.hero-shape span:nth-child(2) {
  width: 560px;
  height: 560px;
}
.hero-shape span:nth-child(3) {
  width: 700px;
  height: 700px;
}

.hero-portrait {
  position: absolute;
  left: calc(50% - 22px);
  transform: translateX(-50%);
  top: 130px;
  width: 240px;
  z-index: -1;
}
@media (min-width: 576px) {
  .hero-portrait {
    left: calc(50% - 36px);
    width: 400px;
    top: 150px;
  }
}
@media (min-width: 992px) {
  .hero-portrait {
    right: 0px;
    width: 400px;
    bottom: 0;
    top: auto;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .hero-portrait {
    right: -155px;
    width: auto;
  }
}
@media (min-width: 1800px) {
  .hero-portrait {
    right: 0;
  }
}
.hero-portrait .image {
  height: auto;
  position: relative;
  z-index: 8;
}
@media (min-width: 992px) {
  .hero-portrait .image {
    height: 570px;
  }
}
@media (min-width: 1200px) {
  .hero-portrait .image {
    height: 700px;
  }
}
@media (min-width: 1800px) {
  .hero-portrait .image {
    height: 950px;
  }
}
.hero-portrait .image-half-round-shape {
  bottom: 10%;
  left: 50%;
  position: absolute;
  width: 150px;
  height: 250px;
  border-top-right-radius: 150px;
  border-bottom-right-radius: 150px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-left: 0;
  z-index: -1;
}
@media (min-width: 576px) {
  .hero-portrait .image-half-round-shape {
    width: 260px;
    height: 400px;
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
  }
}
@media (min-width: 992px) {
  .hero-portrait .image-half-round-shape {
    bottom: 0%;
    left: 60%;
    width: 260px;
    height: 464px;
    border-top-right-radius: 250px;
    border-bottom-right-radius: 250px;
  }
}
@media (min-width: 1200px) {
  .hero-portrait .image-half-round-shape {
    width: 350px;
    height: 600px;
    border-top-right-radius: 350px;
    border-bottom-right-radius: 350px;
  }
}
@media (min-width: 1800px) {
  .hero-portrait .image-half-round-shape {
    width: 400px;
    height: 800px;
    border-top-right-radius: 400px;
    border-bottom-right-radius: 400px;
  }
}
.hero-portrait .image-half-round-shape::before {
  bottom: 68px;
  left: -75px;
  position: absolute;
  content: "";
  width: 400px;
  height: 660px;
  border-top-right-radius: 400px;
  border-bottom-right-radius: 400px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-left: 0;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
}
@media (min-width: 992px) {
  .hero-portrait .image-half-round-shape::before {
    visibility: visible;
    opacity: 1;
    bottom: 50px;
    left: 0;
    width: 220px;
    height: 370px;
    border-top-right-radius: 220px;
    border-bottom-right-radius: 220px;
  }
}
@media (min-width: 1200px) {
  .hero-portrait .image-half-round-shape::before {
    bottom: 50px;
    left: 0;
    width: 300px;
    height: 500px;
    border-top-right-radius: 350px;
    border-bottom-right-radius: 350px;
  }
}
@media (min-width: 1800px) {
  .hero-portrait .image-half-round-shape::before {
    bottom: 68px;
    left: -75px;
    width: 400px;
    height: 660px;
    border-top-right-radius: 400px;
    border-bottom-right-radius: 400px;
  }
}
.hero-portrait .image-half-round-shape::after {
  bottom: -46px;
  left: 24px;
  position: absolute;
  content: "";
  width: 450px;
  height: 900px;
  border-top-right-radius: 450px;
  border-bottom-right-radius: 450px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-left: 0;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
}
@media (min-width: 992px) {
  .hero-portrait .image-half-round-shape::after {
    visibility: visible;
    opacity: 1;
    bottom: -28px;
    left: -69px;
    width: 396px;
    height: 540px;
    border-top-right-radius: 340px;
    border-bottom-right-radius: 340px;
  }
}
@media (min-width: 1200px) {
  .hero-portrait .image-half-round-shape::after {
    bottom: -40px;
    left: -64px;
    width: 478px;
    height: 693px;
    border-top-right-radius: 400px;
    border-bottom-right-radius: 400px;
  }
}
@media (min-width: 1800px) {
  .hero-portrait .image-half-round-shape::after {
    bottom: -46px;
    left: 24px;
    width: 450px;
    height: 900px;
    border-top-right-radius: 450px;
    border-bottom-right-radius: 450px;
  }
}
.hero-portrait .social-link a {
  display: block;
  text-align: center;
  color: #fff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  border-radius: 50%;
  border: 1px solid #fff;
  z-index: 11;
  position: absolute;
  background: #252734;
}
@media (min-width: 576px) {
  .hero-portrait .social-link a {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}
@media (min-width: 768px) {
  .hero-portrait .social-link a {
    font-size: 20px;
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}
@media (min-width: 992px) {
  .hero-portrait .social-link a {
    font-size: 20px;
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}
@media (min-width: 1200px) {
  .hero-portrait .social-link a {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}
@media (min-width: 1800px) {
  .hero-portrait .social-link a {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
}
.hero-portrait .social-link a:hover {
  color: #252734;
  background: #fff;
}
.hero-portrait .social-link a:nth-child(1) {
  left: 175px;
  top: 50px;
}
@media (min-width: 576px) {
  .hero-portrait .social-link a:nth-child(1) {
    left: 315px;
    top: 110px;
  }
}
@media (min-width: 992px) {
  .hero-portrait .social-link a:nth-child(1) {
    left: 335px;
    top: 96px;
  }
}
@media (min-width: 1200px) {
  .hero-portrait .social-link a:nth-child(1) {
    left: 535px;
    top: 96px;
  }
}
@media (min-width: 1800px) {
  .hero-portrait .social-link a:nth-child(1) {
    left: 590px;
    top: 185px;
  }
}
.hero-portrait .social-link a:nth-child(2) {
  left: 225px;
  top: 90px;
}
@media (min-width: 576px) {
  .hero-portrait .social-link a:nth-child(2) {
    left: 395px;
    top: 170px;
  }
}
@media (min-width: 992px) {
  .hero-portrait .social-link a:nth-child(2) {
    left: 430px;
    top: 188px;
  }
}
@media (min-width: 1200px) {
  .hero-portrait .social-link a:nth-child(2) {
    left: 655px;
    top: 188px;
  }
}
@media (min-width: 1800px) {
  .hero-portrait .social-link a:nth-child(2) {
    left: 715px;
    top: 336px;
  }
}
.hero-portrait .social-link a:nth-child(3) {
  left: 250px;
  top: 150px;
}
@media (min-width: 576px) {
  .hero-portrait .social-link a:nth-child(3) {
    left: 435px;
    top: 255px;
  }
}
@media (min-width: 992px) {
  .hero-portrait .social-link a:nth-child(3) {
    left: 470px;
    top: 325px;
  }
}
@media (min-width: 1200px) {
  .hero-portrait .social-link a:nth-child(3) {
    left: 715px;
    top: 325px;
  }
}
@media (min-width: 1800px) {
  .hero-portrait .social-link a:nth-child(3) {
    left: 758px;
    top: 540px;
  }
}
.hero-portrait .social-link a:nth-child(4) {
  left: 247px;
  top: 213px;
}
@media (min-width: 576px) {
  .hero-portrait .social-link a:nth-child(4) {
    left: 430px;
    top: 348px;
  }
}
@media (min-width: 992px) {
  .hero-portrait .social-link a:nth-child(4) {
    left: 400px;
    top: 480px;
  }
}
@media (min-width: 1200px) {
  .hero-portrait .social-link a:nth-child(4) {
    left: 700px;
    top: 480px;
  }
}
@media (min-width: 1800px) {
  .hero-portrait .social-link a:nth-child(4) {
    left: 687px;
    top: 740px;
  }
}

/*****************************
* Component - Service Display
*****************************/
.service-display-wrapper {
  position: relative;
  padding-bottom: 40px;
}
@media (min-width: 1800px) {
  .service-display-wrapper {
    position: static;
    padding-bottom: 0px;
  }
}

/*Service Slider */
.service-display-dots {
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 100%;
}
@media (min-width: 1800px) {
  .service-display-dots {
    position: absolute;
    background: rgba(37, 39, 52, 0.6);
    height: 900px;
    width: 322px;
    top: 0;
    right: 0;
  }
}
.service-display-dots .swiper-pagination {
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 1800px) {
  .service-display-dots .swiper-pagination {
    position: absolute;
    bottom: 240px;
  }
}
.service-display-dots .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  display: inline-block;
  background: #fff;
  opacity: 0.09;
  margin-right: 20px;
  margin-bottom: 0px;
  border-radius: 50%;
}
.service-display-dots .swiper-pagination-bullet-active {
  opacity: 1;
}
.service-display-dots .swiper-pagination-bullet:last-child {
  margin-bottom: 0;
}
.service-display-dots .swiper-pagination-bullet:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
@media (min-width: 1800px) {
  .service-display-dots .swiper-pagination-bullet {
    display: block;
    width: 12px;
    margin-right: 0;
    margin-bottom: 70px;
  }
}

/*Service Box Single Item*/
.service-box-single-item {
  position: relative;
  background: #252734;
  padding: 45px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .service-box-single-item {
    padding: 50px;
  }
}
@media (min-width: 992px) {
  .service-box-single-item {
    padding: 70px;
  }
}
.service-box-single-item .icon {
  width: 56px;
  height: 56px;
  margin-bottom: 60px;
}
.service-box-single-item .icon img {
  width: 100%;
}
.service-box-single-item .title {
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 45px;
}
.service-box-single-item .title a {
  color: #fff;
}
.service-box-single-item .list-item li {
  position: relative;
  margin-bottom: 15px;
  display: block;
  padding-left: 40px;
}
.service-box-single-item .list-item li:last-child {
  margin-bottom: 0;
}
.service-box-single-item .list-item li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  font-family: "IcoFont";
}
.service-box-single-item:hover .inner-shape {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

/************************************
* Component - Skill Progress Display
************************************/
.skill-display-section-box .section-content .btn {
  margin-top: 30px;
}
@media (min-width: 992px) {
  .skill-display-section-box .section-content .btn {
    margin-top: 50px;
  }
}
@media (min-width: 1200px) {
  .skill-display-section-box .section-content .btn {
    margin-top: 90px;
  }
}
@media (min-width: 1400px) {
  .skill-display-section-box .section-content .btn {
    margin-top: 100px;
  }
}

.skill-display-wrapper {
  margin: 0;
}
@media (min-width: 1800px) {
  .skill-display-wrapper {
    margin-right: 130px;
  }
}

.skill-progress-single-item {
  background: #2a2c39;
  padding: 25px 25px 30px 25px;
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .skill-progress-single-item {
    padding: 45px 45px 50px 45px;
  }
}
.skill-progress-single-item:last-child {
  margin-bottom: 0;
}
.skill-progress-single-item .tag {
  margin-bottom: 25px;
  display: inline-block;
  background: #2a2c39;
  position: relative;
  z-index: 2;
}
.skill-progress-single-item .skill-box {
  width: 100%;
  height: 5px;
  background: rgba(255, 255, 255, 0.1);
  position: relative;
}
.skill-progress-single-item .progress-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #fff;
  transition: all 1s linear;
  width: 0;
}
.skill-progress-single-item .progress-line .skill-percentage {
  font-size: 15px;
  text-transform: uppercase;
  position: absolute;
  right: -15px;
  top: -45px;
  z-index: 1;
}

.skill-display-shape {
  position: absolute;
  width: 375px;
  height: 100%;
  top: 0;
  right: 0;
  background: #2a2c39;
  visibility: hidden;
  opacity: 0;
}
@media (min-width: 1800px) {
  .skill-display-shape {
    visibility: visible;
    opacity: 1;
  }
}

/************************************
* Component - Counterup Display
************************************/
.counterup-single-item {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 992px) {
  .counterup-single-item {
    align-items: flex-start;
    flex-direction: row;
    text-align: left;
  }
}
.counterup-single-item .icon {
  width: 70px;
  height: 70px;
  border: 1px solid #40424d;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .counterup-single-item .icon {
    margin-right: 50px;
    margin-bottom: 0;
  }
}
.counterup-single-item .icon img {
  width: 25px;
  height: auto;
}
.counterup-single-item .content {
  text-align: center;
}
@media (min-width: 992px) {
  .counterup-single-item .content {
    text-align: left;
  }
}
.counterup-single-item .content .number {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 300;
  margin-top: -10px;
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .counterup-single-item .content .number {
    font-size: 52px;
  }
}

.counter-display-wrapper {
  margin-bottom: -60px;
}
.counter-display-wrapper .row .col-12 {
  margin-bottom: 60px;
}

/************************************
* Component - Solution Display
************************************/
.solution-display-wrapper {
  padding: 0 30px;
}
@media (min-width: 576px) {
  .solution-display-wrapper {
    padding: 0 50px;
  }
}
@media (min-width: 1800px) {
  .solution-display-wrapper {
    padding: 0;
  }
}
@media (min-width: 1800px) {
  .solution-display-wrapper .swiper-wrapper {
    left: -270px;
  }
}
@media (min-width: 1800px) {
  .solution-display-wrapper .solution-box-single-item {
    width: 735px !important;
  }
}

.solution-box-single-item .img-box {
  overflow: hidden;
  padding: 30px 30px 0 30px;
  height: 300px;
  position: relative;
  z-index: 1;
}
@media (min-width: 992px) {
  .solution-box-single-item .img-box {
    padding: 50px 50px 0 50px;
    height: 370px;
  }
}
@media (min-width: 992px) {
  .solution-box-single-item .img-box {
    padding: 50px 50px 0 50px;
    height: 240px;
  }
}
@media (min-width: 992px) {
  .solution-box-single-item .img-box {
    padding: 50px 50px 0 50px;
    height: 335px;
  }
}
@media (min-width: 1200px) {
  .solution-box-single-item .img-box {
    padding: 100px 100px 0 100px;
    height: 450px;
  }
}
@media (min-width: 1400px) {
  .solution-box-single-item .img-box {
    padding: 100px 100px 0 100px;
    height: 548px;
  }
}
.solution-box-single-item .img-box .bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(37, 39, 52, 0.95);
}
.solution-box-single-item .img-box .bg-image {
  position: absolute;
  transform: translate(-50%, -50%) rotate(-45deg);
  top: 50%;
  left: 50%;
  z-index: -1;
}
.solution-box-single-item .img-box .image {
  position: relative;
  z-index: 1;
}
.solution-box-single-item .img-box .image img {
  width: 100%;
}
.solution-box-single-item .title {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 500;
  margin-top: 40px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .solution-box-single-item .title {
    font-size: 28px;
    margin-top: 55px;
    margin-bottom: 25px;
  }
}
.solution-box-single-item .title a {
  color: #fff;
}
.solution-box-single-item .catagory-nav-item {
  font-weight: 300;
}
.solution-box-single-item .catagory-nav-item li {
  display: inline-block;
  position: relative;
  margin-right: 10px;
}
.solution-box-single-item .catagory-nav-item li::after {
  position: absolute;
  content: "/";
  left: calc(100% + 5px);
}
.solution-box-single-item .catagory-nav-item li:last-child::after {
  display: none;
}

/************************************
* Component - Testimonial Display
************************************/
.testimonial-display-box .default-nav-style {
  z-index: 9;
}
@media (min-width: 1200px) {
  .testimonial-display-box .default-nav-style {
    position: absolute;
    bottom: 44px;
    transform: translateX(-50%);
    left: calc(50% - 150px);
  }
}
@media (min-width: 1400px) {
  .testimonial-display-box .default-nav-style {
    left: calc(50% - 170px);
  }
}
@media (min-width: 1800px) {
  .testimonial-display-box .default-nav-style {
    left: calc(50% - 190px);
  }
}

.testimonial-display-slider .swiper-container {
  overflow: visible;
}
.testimonial-display-slider .swiper-slide-active {
  bottom: 0;
}
@media (min-width: 1200px) {
  .testimonial-display-slider .swiper-slide-active {
    bottom: 230px;
  }
}

.testimonial-slider-single-item {
  background: #282a37;
  padding: 100px 45px 45px 45px;
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  .testimonial-slider-single-item {
    padding: 165px 55px 70px 55px;
  }
}
.testimonial-slider-single-item .icon {
  position: absolute;
  font-family: "Cormorant Garamond", serif;
  font-size: 185px;
  top: 0px;
  left: 45px;
  line-height: 1;
}
@media (min-width: 768px) {
  .testimonial-slider-single-item .icon {
    left: 55px;
    top: 60px;
  }
}
.testimonial-slider-single-item .text {
  font-size: 28px;
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
  color: #fff;
}
@media (min-width: 768px) {
  .testimonial-slider-single-item .text {
    font-size: 32px;
  }
}
.testimonial-slider-single-item .info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
@media (min-width: 768px) {
  .testimonial-slider-single-item .info {
    flex-direction: row;
    align-items: center;
  }
}
.testimonial-slider-single-item .name {
  font-size: 25px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-top: 5px;
  margin-bottom: 20px;
}
.testimonial-slider-single-item .designation {
  display: inline-block;
  font-weight: 300;
}
.testimonial-slider-single-item .review {
  display: inline-flex;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .testimonial-slider-single-item .review {
    margin-top: 0;
  }
}
.testimonial-slider-single-item .review li {
  margin-right: 10px;
}
.testimonial-slider-single-item .review li:last-child {
  margin-right: 0;
}
.testimonial-slider-single-item .review .fill {
  color: #ffa200;
}
.testimonial-slider-single-item .review .blank {
  color: #828282;
}
.testimonial-slider-single-item:hover .inner-shape {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

/************************************
* Component - Company Logo Display
************************************/
.company-logo-single-item {
  border: 1px solid #353743;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.company-logo-single-item .image {
  width: 142px;
  height: 142px;
  display: block;
  text-align: center;
  position: relative;
}
.company-logo-single-item .image img {
  width: 100%;
}
.company-logo-single-item .image img:nth-child(1) {
  opacity: 1;
}
.company-logo-single-item .image img:nth-child(2) {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
}
.company-logo-single-item:hover {
  border-color: #252734;
  background: #252734;
}
.company-logo-single-item:hover .image img:nth-child(1) {
  opacity: 0;
}
.company-logo-single-item:hover .image img:nth-child(2) {
  opacity: 1;
}

/************************************
* Component - Blog Feed Display
************************************/
.blog-feed-single-item {
  overflow: hidden;
  position: relative;
  padding: 30px;
  background: #282a37;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .blog-feed-single-item {
    padding: 40px;
    align-items: center;
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .blog-feed-single-item {
    padding: 50px;
  }
}
.blog-feed-single-item .content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 992px) {
  .blog-feed-single-item .content-box {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.blog-feed-single-item .image {
  width: 100%;
  margin-right: 0px;
}
@media (min-width: 768px) {
  .blog-feed-single-item .image {
    width: 280px;
    height: auto;
    margin-right: 30px;
  }
}
@media (min-width: 992px) {
  .blog-feed-single-item .image {
    width: 180px;
    margin-right: 70px;
  }
}
.blog-feed-single-item .image img {
  width: 100%;
  object-fit: cover;
}
.blog-feed-single-item .content {
  margin-top: 40px;
}
@media (min-width: 992px) {
  .blog-feed-single-item .content {
    margin-top: 0;
    width: 450px;
    margin-right: 100px;
  }
}
.blog-feed-single-item .content .post-meta a {
  font-weight: 300;
  margin-right: 35px;
  position: relative;
}
.blog-feed-single-item .content .post-meta a::after {
  position: absolute;
  content: "-";
  left: calc(100% + 17.5px);
}
.blog-feed-single-item .content .post-meta a:last-child {
  margin-right: 0;
}
.blog-feed-single-item .content .post-meta a:last-child::after {
  display: none;
}
.blog-feed-single-item .content .post-meta .catagory {
  color: #fff;
}
.blog-feed-single-item .content .title {
  margin-top: 23px;
  line-height: 36px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
@media (min-width: 576px) {
  .blog-feed-single-item .content .title {
    line-height: 42px;
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .blog-feed-single-item .content .title {
    line-height: 42px;
    font-size: 25px;
  }
}
.blog-feed-single-item .content .title a {
  color: #fff;
}
.blog-feed-single-item .btn {
  margin-top: 30px;
}
@media (min-width: 1200px) {
  .blog-feed-single-item .btn {
    margin-top: 0;
  }
}
.blog-feed-single-item:hover .inner-shape {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

/************************************
* Component - Video Card Display
************************************/
.video-card {
  padding: 30px;
  background: #252734;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 276px;
}
@media (min-width: 576px) {
  .video-card {
    height: 336px;
  }
}
@media (min-width: 992px) {
  .video-card {
    height: 456px;
  }
}
@media (min-width: 1200px) {
  .video-card {
    height: 463.5px;
  }
}
.video-card .btn {
  margin-top: 55px;
}
@media (min-width: 576px) {
  .video-card .btn {
    margin-top: 65px;
  }
}
@media (min-width: 768px) {
  .video-card .btn {
    margin-top: 75px;
  }
}
@media (min-width: 992px) {
  .video-card .btn {
    margin-top: 85px;
  }
}
@media (min-width: 1200px) {
  .video-card .btn {
    margin-top: 95px;
  }
}

.video-info-content {
  text-align: center;
  margin-top: 70px;
}
@media (min-width: 992px) {
  .video-info-content {
    margin-top: 0;
    text-align: left;
  }
}
.video-info-content .title {
  font-size: 50px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .video-info-content .title {
    font-size: 60px;
  }
}
@media (min-width: 992px) {
  .video-info-content .title {
    font-size: 70px;
  }
}
@media (min-width: 1800px) {
  .video-info-content .title {
    font-size: 85px;
  }
}
.video-info-content .sub-title {
  font-size: 22px;
  font-weight: 600;
  color: #858792;
  line-height: 1.5;
}
@media (min-width: 576px) {
  .video-info-content .sub-title {
    font-size: 34px;
  }
}
@media (min-width: 992px) {
  .video-info-content .sub-title {
    font-size: 36px;
  }
}
@media (min-width: 1200px) {
  .video-info-content .sub-title {
    font-size: 40px;
  }
}
@media (min-width: 1400px) {
  .video-info-content .sub-title {
    font-size: 45px;
  }
}
.video-info-content p {
  margin-top: 25px;
}
.video-info-content p:last-child {
  margin-bottom: 0;
}

/************************************
* Component - Resume Display
************************************/
.resume-tab {
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 60px;
}
@media (min-width: 992px) {
  .resume-tab {
    margin-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .resume-tab {
    margin-bottom: 100px;
  }
}
.resume-tab .nav-item {
  width: 100%;
  margin-bottom: 35px;
}
.resume-tab .nav-item:last-child {
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .resume-tab .nav-item {
    width: calc(50% - 17.5px);
    margin-right: 35px;
    margin-bottom: 0;
  }
}
.resume-tab .nav-item:last-child {
  margin-right: 0;
}
.resume-tab .nav-item button {
  background: #252734;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 36px;
  font-family: "Cormorant Garamond", serif;
  color: #fff;
  display: block;
  width: 100%;
}
@media (min-width: 576px) {
  .resume-tab .nav-item button {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .resume-tab .nav-item button {
    font-size: 45px;
  }
}
@media (min-width: 992px) {
  .resume-tab .nav-item button {
    font-size: 50px;
  }
}
@media (min-width: 1200px) {
  .resume-tab .nav-item button {
    font-size: 58px;
  }
}
.resume-tab .nav-item button.active {
  border-color: #2a2c39;
  background: #2a2c39;
}

.resume-tab-list-single-item:last-child > .content > .right {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .resume-tab-list-single-item:first-child > .content > .left {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .resume-tab-list-single-item:last-child > .content > .left {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}
.resume-tab-list-single-item .content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media (min-width: 992px) {
  .resume-tab-list-single-item .content {
    align-items: center;
    flex-direction: row;
  }
}
.resume-tab-list-single-item .left {
  width: 100%;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 25px;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .resume-tab-list-single-item .left {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-top: none;
    border-bottom: none;
    padding: 125px 30px 125px 170px;
    margin-right: 80px;
    width: 300px;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .resume-tab-list-single-item .left {
    padding: 103px 30px 105px 170px;
    margin-right: 100px;
  }
}
.resume-tab-list-single-item .left::after {
  position: absolute;
  content: "";
  height: 50px;
  width: 1px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #fff;
}
.resume-tab-list-single-item .left .year {
  display: block;
}
.resume-tab-list-single-item .right {
  margin-bottom: 60px;
  width: 100%;
}
@media (min-width: 992px) {
  .resume-tab-list-single-item .right {
    width: calc(100% - 400px);
    margin-bottom: 35px;
  }
}
.resume-tab-list-single-item .right .title {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 25px;
}
.resume-tab-list-single-item .right p:last-child {
  margin-top: 25px;
  margin-bottom: 0;
}

/************************************
* Component - Breadcrumb
************************************/
.breadcrumb-box {
  padding-top: 130px;
  padding-bottom: 45px;
}
@media (min-width: 768px) {
  .breadcrumb-box {
    padding-top: 175px;
    padding-bottom: 90px;
  }
}
@media (min-width: 992px) {
  .breadcrumb-box {
    padding-top: 175px;
    padding-bottom: 110px;
  }
}
@media (min-width: 1400px) {
  .breadcrumb-box {
    padding-top: 225px;
    padding-bottom: 130px;
  }
}
@media (min-width: 1800px) {
  .breadcrumb-box {
    padding-top: 275px;
    padding-bottom: 180px;
  }
}

.breadcrumb-content {
  text-align: center;
}
.breadcrumb-content .title {
  font-size: 40px;
  font-weight: 600;
}
@media (min-width: 768px) {
  .breadcrumb-content .title {
    font-size: 50px;
  }
}
@media (min-width: 992px) {
  .breadcrumb-content .title {
    font-size: 75px;
  }
}
@media (min-width: 1800px) {
  .breadcrumb-content .title {
    font-size: 90px;
  }
}

.breadcrumb-link {
  margin-top: 20px;
  padding: 20px 40px;
  border-radius: 30px;
  background: #2a2c39;
  display: inline-flex;
}
.breadcrumb-link li {
  position: relative;
  margin-right: 30px;
  font-weight: 300;
  font-size: 14px;
}
@media (min-width: 992px) {
  .breadcrumb-link li {
    font-size: 16px;
  }
}
.breadcrumb-link li::after {
  position: absolute;
  content: "||";
  left: calc(100% + 10px);
  visibility: visible;
}
.breadcrumb-link li:last-child {
  margin-right: 0;
}
.breadcrumb-link li:last-child::after {
  visibility: hidden;
}
.breadcrumb-link li.active {
  color: #fff;
}

.breadcrumb-shape-top-left {
  position: absolute;
  width: 800px;
  height: 800px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  top: -375px;
  left: -585px;
  visibility: hidden;
  opacity: 0;
}
@media (min-width: 1200px) {
  .breadcrumb-shape-top-left {
    visibility: visible;
    opacity: 1;
    top: -475px;
    left: -590px;
  }
}
@media (min-width: 1800px) {
  .breadcrumb-shape-top-left {
    top: -425px;
    left: -595px;
  }
}
.breadcrumb-shape-top-left::before {
  position: absolute;
  content: "";
  width: 935px;
  height: 935px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  top: -70px;
  left: -70px;
}
.breadcrumb-shape-top-left::after {
  position: absolute;
  content: "";
  width: 1065px;
  height: 1065px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  top: -140px;
  left: -140px;
}

.breadcrumb-shape-bottom-right {
  position: absolute;
  width: 800px;
  height: 800px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  bottom: -375px;
  right: -585px;
  visibility: hidden;
  opacity: 0;
}
@media (min-width: 1200px) {
  .breadcrumb-shape-bottom-right {
    visibility: visible;
    opacity: 1;
    bottom: -475px;
    right: -590px;
  }
}
@media (min-width: 1800px) {
  .breadcrumb-shape-bottom-right {
    bottom: -425px;
    right: -595px;
  }
}
.breadcrumb-shape-bottom-right::before {
  position: absolute;
  content: "";
  width: 935px;
  height: 935px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  bottom: -70px;
  right: -70px;
}
.breadcrumb-shape-bottom-right::after {
  position: absolute;
  content: "";
  width: 1065px;
  height: 1065px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  bottom: -140px;
  right: -140px;
}

/************************************
* Component - Sidebar Widgets
************************************/
.sidebar-widget-area {
  margin-top: 75px;
}
@media (min-width: 992px) {
  .sidebar-widget-area {
    margin-top: 0;
  }
}

.sidebar-widget-single-area {
  background: #252734;
  padding: 40px 40px 40px 40px;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .sidebar-widget-single-area {
    padding: 60px 50px 75px 50px;
  }
}
@media (min-width: 1200px) {
  .sidebar-widget-single-area {
    margin-bottom: 80px;
  }
}
.sidebar-widget-single-area:last-child {
  margin-bottom: 0;
}
.sidebar-widget-single-area .title {
  font-size: 34px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .sidebar-widget-single-area .title {
    font-size: 40px;
    margin-bottom: 45px;
  }
}
@media (min-width: 992px) {
  .sidebar-widget-single-area .title {
    font-size: 40px;
  }
}
@media (min-width: 1200px) {
  .sidebar-widget-single-area .title {
    font-size: 48px;
  }
}

.search-widgets-box {
  position: relative;
}
.search-widgets-box input {
  background: #2a2c39;
  padding: 20px 60px 20px 25px;
  border: none;
  color: #fff;
  width: 100%;
}
.search-widgets-box button {
  position: absolute;
  border: none;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  background: transparent;
  color: #fff;
}

.widget-nav-list li {
  display: block;
  margin-bottom: 20px;
}
.widget-nav-list li:last-child {
  margin-bottom: 0;
}
.widget-nav-list li a {
  padding: 20px 30px;
  background: #2a2c39;
  color: #fff;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: all 0.2s ease;
}
.widget-nav-list li a .text, .widget-nav-list li a .icon {
  display: inline-block;
  transition: all 0.5s ease;
}
.widget-nav-list li a:hover {
  background: #fff;
  color: #252734;
}
.widget-nav-list li a:hover .text {
  transform: translateX(30px);
}
.widget-nav-list li a:hover .icon {
  position: absolute;
  left: 0px;
  transform: translateX(30px);
}

.recent-blog-item-list li {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}
.recent-blog-item-list li:last-child {
  margin-bottom: 0;
}
.recent-blog-item-list .image {
  width: 70px;
  height: 70px;
  overflow: hidden;
}
.recent-blog-item-list .image img {
  width: 100%;
  border-radius: 50%;
}
.recent-blog-item-list .content {
  width: calc(100% - 70px);
  padding-left: 25px;
}
.recent-blog-item-list .content .title {
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: 700;
  line-height: 1.27;
}
.recent-blog-item-list .content .title a {
  color: #fff;
}

/************************************
* Component - Pricing Area
************************************/
.pricing-area {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 50px;
  margin-top: 43px;
}
@media (min-width: 1200px) {
  .pricing-area {
    grid-template-columns: repeat(2, 1fr);
  }
}

.pricing-single-item {
  text-align: center;
  position: relative;
  overflow: hidden;
  background: #252734;
  padding: 40px 30px;
}
@media (min-width: 768px) {
  .pricing-single-item {
    padding: 70px 30px;
  }
}
.pricing-single-item .name {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .pricing-single-item .name {
    margin-bottom: 25px;
  }
}
.pricing-single-item .value {
  font-size: 46px;
  font-family: "Poppins", sans-serif;
}
@media (min-width: 768px) {
  .pricing-single-item .value {
    font-size: 58px;
  }
}
.pricing-single-item .time {
  text-align: right;
  padding-left: 75px;
  display: inline-block;
}
.pricing-single-item .list-items {
  margin-top: 30px;
  margin-bottom: 30px;
}
.pricing-single-item .list-items li {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .pricing-single-item .list-items {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
.pricing-single-item:hover .inner-shape {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

/************************************
* Component - Pagination
************************************/
.pagination-nav-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  margin-bottom: -20px;
}
.pagination-nav-list li {
  margin-right: 20px;
  margin-bottom: 20px;
}
.pagination-nav-list li:last-child {
  margin-right: 0;
}
.pagination-nav-list li.active a {
  background: #252734;
  border-color: #252734;
}
.pagination-nav-list a {
  width: 45px;
  height: 45px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  line-height: 45px;
  text-align: center;
  display: inline-block;
}
@media (min-width: 768px) {
  .pagination-nav-list a {
    width: 65px;
    height: 65px;
    line-height: 65px;
  }
}
.pagination-nav-list a:hover {
  background: #252734;
  border-color: #252734;
}

/************************************
* Component - Tag box
************************************/
.tag-box {
  background: #252734;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 50px;
}
.tag-box > * {
  margin-bottom: 20px;
}
.tag-box > *:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .tag-box > * {
    margin-bottom: 0;
  }
}
.tag-box .tag-list .title {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}
.tag-box .tag-list .list-item {
  display: flex;
  flex-wrap: wrap;
}
.tag-box .tag-list .list-item li {
  position: relative;
  margin-right: 10px;
}
.tag-box .tag-list .list-item li:after {
  position: absolute;
  content: ",";
  left: calc(100% + 0px);
}
.tag-box .tag-list .list-item li:last-child {
  margin-right: 0px;
}
.tag-box .tag-list .list-item li:last-child:after {
  display: none;
}

/************************************
* Component - Comment
************************************/
.comment-box .title {
  font-size: 36px;
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .comment-box .title {
    font-size: 45px;
  }
}
@media (min-width: 768px) {
  .comment-box .title {
    font-size: 45px;
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .comment-box .title {
    font-size: 50px;
    margin-bottom: 50px;
  }
}
@media (min-width: 1200px) {
  .comment-box .title {
    font-size: 55px;
  }
}
.comment-box .comment-list-item > li {
  margin-bottom: 40px;
}
.comment-box .comment-list-item > li:last-child {
  margin-bottom: 0;
}
.comment-box .comment-single-item {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media (min-width: 576px) {
  .comment-box .comment-single-item {
    flex-direction: row;
  }
}
.comment-box .comment-single-item .image {
  width: 100px;
  height: 100px;
  margin-right: 0px;
  margin-bottom: 40px;
}
@media (min-width: 576px) {
  .comment-box .comment-single-item .image {
    margin-right: 45px;
    margin-bottom: 0px;
  }
}
.comment-box .comment-single-item .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.comment-box .comment-single-item .content {
  width: 100%;
}
@media (min-width: 576px) {
  .comment-box .comment-single-item .content {
    width: calc(100% - 145px);
  }
}
.comment-box .comment-single-item .content .top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}
.comment-box .comment-single-item .content .author-meta .name {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: -10px;
}
.comment-box .comment-single-item .content .designation {
  display: block;
}
.comment-box .comment-single-item .content .replay-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 600px;
}
.comment-box .comment-single-item .content .bottom .text p:last-child {
  margin-bottom: 0;
}
.comment-box .has-reply > .comment-single-item {
  margin-left: 0px;
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .comment-box .has-reply > .comment-single-item {
    margin-left: 50px;
  }
}
@media (min-width: 1200px) {
  .comment-box .has-reply > .comment-single-item {
    margin-left: 100px;
  }
}
.comment-box .has-reply > .comment-single-item:first-child {
  margin-top: 40px;
}
.comment-box .has-reply > .comment-single-item:last-child {
  margin-bottom: 0;
}

.comment-form-box .title {
  font-size: 36px;
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .comment-form-box .title {
    font-size: 45px;
  }
}
@media (min-width: 768px) {
  .comment-form-box .title {
    font-size: 45px;
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .comment-form-box .title {
    font-size: 50px;
    margin-bottom: 50px;
  }
}
@media (min-width: 1200px) {
  .comment-form-box .title {
    font-size: 55px;
  }
}

/************************************
* Pages - Solution
************************************/
.solutions-gallery-filter-nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.solutions-gallery-filter-nav .btn {
  margin-right: 20px;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .solutions-gallery-filter-nav .btn {
    margin-right: 30px;
    margin-bottom: 40px;
  }
}
.solutions-gallery-filter-nav .btn-outline-secondary {
  padding: 8.5px 20px;
  border-radius: 30px;
}
@media (min-width: 768px) {
  .solutions-gallery-filter-nav .btn-outline-secondary {
    padding: 12.5px 35px;
  }
}

.solutions-wrapper-gallery-content {
  margin-bottom: -60px;
}
.solutions-wrapper-gallery-content .filter-item {
  margin-bottom: 60px;
}

.solution-hero-image {
  margin-bottom: 65px;
  height: 350px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .solution-hero-image {
    height: 400px;
  }
}
@media (min-width: 992px) {
  .solution-hero-image {
    height: 500px;
  }
}
@media (min-width: 1200px) {
  .solution-hero-image {
    height: 600px;
  }
}
.solution-hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.solution-sidebar {
  background: #252734;
  padding: 60px 40px 75px 40px;
  margin-top: 60px;
}
@media (min-width: 992px) {
  .solution-sidebar {
    position: absolute;
    top: calc(0% + 420px);
    right: 15px;
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  .solution-sidebar {
    top: calc(0% + 560px);
  }
}
.solution-sidebar .title {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 45px;
}
@media (min-width: 768px) {
  .solution-sidebar .title {
    font-size: 32px;
  }
}
@media (min-width: 992px) {
  .solution-sidebar .title {
    font-size: 34px;
  }
}
@media (min-width: 1200px) {
  .solution-sidebar .title {
    font-size: 38px;
  }
}
.solution-sidebar .solution-sidebar-single-box {
  padding: 25px 30px 25px 30px;
  border: 1px solid #2a2c39;
  background: #2a2c39;
  margin-bottom: 10px;
  transition: all 0.5s ease;
}
.solution-sidebar .solution-sidebar-single-box:last-child {
  margin-bottom: 0;
}
.solution-sidebar .solution-sidebar-single-box .title-text {
  font-size: 15px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
}
.solution-sidebar .solution-sidebar-single-box:hover {
  background: #252734;
  border-color: rgba(255, 255, 255, 0.1);
}

/************************************
* Pages - Blog
************************************/
.blog-list-single-item {
  overflow: hidden;
  position: relative;
  padding: 35px;
  background: #252734;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 50px;
}
.blog-list-single-item:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .blog-list-single-item {
    align-items: center;
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .blog-list-single-item {
    align-items: flex-start;
    padding: 30px;
  }
}
@media (min-width: 1200px) {
  .blog-list-single-item {
    align-items: center;
    padding: 50px;
  }
}
.blog-list-single-item .image {
  width: 100%;
  margin-right: 0px;
}
@media (min-width: 768px) {
  .blog-list-single-item .image {
    width: 280px;
    height: auto;
    margin-right: 30px;
  }
}
@media (min-width: 992px) {
  .blog-list-single-item .image {
    width: 130px;
    margin-right: 30px;
  }
}
@media (min-width: 1200px) {
  .blog-list-single-item .image {
    width: 180px;
    margin-right: 70px;
  }
}
.blog-list-single-item .image img {
  width: 100%;
  object-fit: cover;
}
.blog-list-single-item .content {
  margin-top: 40px;
}
@media (min-width: 768px) {
  .blog-list-single-item .content {
    margin-top: 0px;
  }
}
@media (min-width: 992px) {
  .blog-list-single-item .content {
    margin-top: 0;
    width: calc(100% - 170px);
  }
}
@media (min-width: 1200px) {
  .blog-list-single-item .content {
    margin-top: 0;
    width: calc(100% - 250px);
  }
}
.blog-list-single-item .content .title {
  margin-top: 23px;
  line-height: 36px;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
@media (min-width: 576px) {
  .blog-list-single-item .content .title {
    line-height: 42px;
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .blog-list-single-item .content .title {
    margin-top: 14px;
    line-height: 30px;
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .blog-list-single-item .content .title {
    margin-top: 23px;
    line-height: 42px;
    font-size: 25px;
  }
}
.blog-list-single-item .content .title a {
  color: #fff;
}
.blog-list-single-item .post-meta-2 {
  margin-top: 15px;
}
.blog-list-single-item:hover .inner-shape {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

.post-meta-1 a {
  font-weight: 300;
  margin-right: 35px;
  position: relative;
}
.post-meta-1 a::after {
  position: absolute;
  content: "-";
  left: calc(100% + 17.5px);
}
.post-meta-1 a:last-child {
  margin-right: 0;
}
.post-meta-1 a:last-child::after {
  display: none;
}
.post-meta-1 .catagory {
  color: #fff;
}

.post-meta-2 {
  margin-bottom: -10px;
}
.post-meta-2 a, .post-meta-2 span {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 15px;
}
.post-meta-2 a:last-child, .post-meta-2 span:last-child {
  margin-right: 0;
}
@media (min-width: 768px) {
  .post-meta-2 a, .post-meta-2 span {
    margin-right: 30px;
  }
}
@media (min-width: 992px) {
  .post-meta-2 a, .post-meta-2 span {
    margin-right: 20px;
  }
}
@media (min-width: 1200px) {
  .post-meta-2 a, .post-meta-2 span {
    margin-right: 45px;
  }
}

/************************************
* Pages - FAQ
************************************/
.faq-list-single-item {
  background: #252734;
  border: 1px solid #252734;
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  transition: all 0.5s ease;
}
.faq-list-single-item:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .faq-list-single-item {
    flex-direction: row;
    padding: 50px 45px;
  }
}
@media (min-width: 992px) {
  .faq-list-single-item {
    padding: 60px 55px;
  }
}
.faq-list-single-item .number {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  background: #2a2c39;
  color: #fff;
  font-weight: 500;
  transition: all 0.5s ease;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .faq-list-single-item .number {
    margin-bottom: 0;
    margin-right: 50px;
  }
}
@media (min-width: 992px) {
  .faq-list-single-item .number {
    margin-right: 60px;
  }
}
.faq-list-single-item .content {
  width: 100%;
}
@media (min-width: 768px) {
  .faq-list-single-item .content {
    width: calc(100% - 170px);
  }
}
@media (min-width: 992px) {
  .faq-list-single-item .content {
    width: calc(100% - 180px);
  }
}
.faq-list-single-item .content .title {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  margin-top: -5px;
  margin-bottom: 20px;
}
.faq-list-single-item:hover {
  background: #2a2c39;
  border-color: rgba(255, 255, 255, 0.1);
}
.faq-list-single-item:hover .number {
  background: #252734;
}

/************************************
* Pages - Contact
************************************/
.gmap-box {
  height: 400px;
  overflow: hidden;
}
@media (min-width: 992px) {
  .gmap-box {
    height: 500px;
  }
}
@media (min-width: 1200px) {
  .gmap-box {
    height: 600px;
  }
}
.gmap-box iframe {
  width: 100%;
  height: 100%;
}

@media (min-width: 992px) {
  .contact-sidebar-wapper {
    position: absolute;
    bottom: calc(0% + 400px);
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  .contact-sidebar-wapper {
    bottom: calc(0% + 260px);
  }
}

.contact-sidebar {
  background: #252734;
  padding: 50px 30px 50px 30px;
  margin-top: 60px;
  transition: all 0.5s ease;
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  .contact-sidebar {
    padding: 80px 60px 80px 60px;
  }
}
@media (min-width: 992px) {
  .contact-sidebar {
    margin-bottom: 80px;
    margin-top: 0;
  }
}
.contact-sidebar .title {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 45px;
}
@media (min-width: 768px) {
  .contact-sidebar .title {
    font-size: 32px;
  }
}
@media (min-width: 992px) {
  .contact-sidebar .title {
    font-size: 34px;
  }
}
@media (min-width: 1200px) {
  .contact-sidebar .title {
    font-size: 38px;
  }
}
.contact-sidebar .contact-sidebar-single-box {
  padding: 30px 35px 30px 35px;
  border: 1px solid #2a2c39;
  background: #2a2c39;
  margin-bottom: 20px;
  transition: all 0.5s ease;
}
.contact-sidebar .contact-sidebar-single-box:last-child {
  margin-bottom: 0;
}
.contact-sidebar .contact-sidebar-single-box .title-text {
  font-size: 15px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
}
.contact-sidebar .contact-sidebar-single-box .text, .contact-sidebar .contact-sidebar-single-box .link {
  color: #858792;
}
.contact-sidebar .contact-sidebar-single-box:hover {
  background: #252734;
  border-color: rgba(255, 255, 255, 0.1);
}

.contact-form-wrapper .content {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .contact-form-wrapper .content {
    margin-bottom: 60px;
  }
}
.contact-form-wrapper .content .title {
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  margin-top: 30px;
  margin-bottom: 35px;
}
@media (min-width: 576px) {
  .contact-form-wrapper .content .title {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .contact-form-wrapper .content .title {
    font-size: 45px;
  }
}
@media (min-width: 992px) {
  .contact-form-wrapper .content .title {
    font-size: 55px;
  }
}
@media (min-width: 1200px) {
  .contact-form-wrapper .content .title {
    font-size: 65px;
  }
}
.contact-form-wrapper .content p:last-child {
  margin-bottom: 0;
}

/************************************
* Pages - 404 Error
************************************/
.error-404-box .error-404-content {
  margin-top: 60px;
}
@media (min-width: 768px) {
  .error-404-box .error-404-content {
    margin-top: 0;
  }
}
.error-404-box .error-404-content .title {
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 25px;
}
@media (min-width: 576px) {
  .error-404-box .error-404-content .title {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .error-404-box .error-404-content .title {
    font-size: 45px;
  }
}
@media (min-width: 992px) {
  .error-404-box .error-404-content .title {
    font-size: 55px;
  }
}
@media (min-width: 1200px) {
  .error-404-box .error-404-content .title {
    font-size: 65px;
  }
}
.error-404-box .error-404-content .sub-title {
  font-size: 24px;
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .error-404-box .error-404-content .sub-title {
    font-size: 30px;
  }
}
.error-404-box .error-404-content .btn {
  margin-top: 30px;
}

/************************************
* Layout - Header
************************************/
.header-section {
  position: absolute;
  width: 100%;
  z-index: 999;
  padding-top: 15px;
  padding-bottom: 15px;
}

.header-logo {
  display: block;
}

.header-nav {
  display: flex;
}
@media (min-width: 992px) {
  .header-nav > li {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 1200px) {
  .header-nav > li {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.header-nav > li > a {
  font-size: 18px;
  line-height: 120px;
  display: inline-block;
  color: #858792;
  position: relative;
}
.header-nav > li > a:hover {
  color: #fff;
}
.header-nav > li > a::after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  left: auto;
  left: 50%;
  width: 120px;
  height: 60px;
  background-image: url("../../images/icon/menu-hover-shape.png");
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}
.header-nav > li > a:hover::after {
  transform: translate(-50%, -50%) scale(1);
  visibility: visible;
  opacity: 1;
}
.header-nav .has-dropdown {
  position: relative;
}
.header-nav .has-dropdown:hover .submenu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0) scaleY(1);
}
.header-nav .submenu {
  position: absolute;
  top: 100%;
  left: 0%;
  width: 280px;
  background: #2a2c39;
  border-radius: 0 0 5px 5px;
  visibility: hidden;
  opacity: 0;
  transition: transform 0.1s cubic-bezier(0.2, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateY(0px) scaleY(0.2);
  transform-origin: top;
  transition: all 0.5s ease;
  z-index: 9;
}
.header-nav .submenu a {
  padding: 10px 25px;
  display: block;
  position: relative;
  color: #fff;
  z-index: 1;
}
.header-nav .submenu a::after {
  position: absolute;
  content: "";
  top: 0;
  left: auto;
  right: 0;
  width: 0%;
  height: 100%;
  background: #fff;
  transition: all 0.5s ease;
  z-index: -1;
}
.header-nav .submenu a:hover {
  color: #252734;
}
.header-nav .submenu a:hover::after {
  left: 0;
  width: 100%;
}

/*Main Menu Style*/
.header-section.sticky-header.sticky {
  padding-top: 0;
  padding-bottom: 0;
}

.sticky-header.sticky {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background: #252734;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  animation-name: fadeInDown;
  animation-duration: 900ms;
  animation-timing-function: cubic-bezier(0.2, 1, 0.22, 1);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  border-bottom: 0;
}

.header-btn-link .btn {
  font-size: 18px;
}

/*****************************
* Layout - Mobile Header
*****************************/
.mobile-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #2a2c39;
  padding: 20px 0;
}

.mobile-action-link {
  font-size: 24px;
}
.mobile-action-link a {
  color: #fff;
}

/*****************************
* Layout - Offcanvas
*****************************/
.offcanvas {
  z-index: 999999;
  position: fixed;
  top: 0;
  overflow: auto;
  height: 100vh;
  padding: 20px;
  transition: all 0.5s ease;
  transform: translateX(100%);
  box-shadow: none;
  background: #2a2c39;
  width: 300px;
  overflow-x: hidden;
  visibility: visible;
}
@media (min-width: 576px) {
  .offcanvas {
    width: 400px;
  }
}

.offcanvas-leftside {
  left: 0%;
  transform: translateX(-100%);
}

.offcanvas-rightside {
  right: 0%;
  transform: translateX(100%);
}

.offcanvas.offcanvas-open {
  transform: translateX(0);
}

.offcanvas-overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.offcanvas-header {
  margin-bottom: 30px;
}

.offcanvas-close {
  width: 40px;
  height: 40px;
  font-size: 22px;
  line-height: 38px;
  text-align: center;
  border-radius: 50%;
  transition: all 0.5s ease;
  color: #fff;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.offcanvas-title {
  margin-bottom: 40px;
}

/*****************************
* Layout - Offcanvas Menu
*****************************/
/*Mobile Menu Bottom Area*/
.offcanvas-menu {
  margin: 20px 0;
}

.offcanvas-menu li {
  position: relative;
  padding: 7px 5px;
}

.offcanvas-menu > ul > li {
  border-bottom: 1px solid transparent;
}

.offcanvas-menu > ul > li:last-child {
  border-bottom: none;
}

.offcanvas-menu li a {
  display: block;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}

.offcanvas-menu li a:hover {
  color: #fff;
}

.offcanvas-menu li .mobile-sub-menu {
  display: none;
  cursor: pointer;
}

.offcanvas-menu-expand::after {
  content: "";
  position: absolute;
  font-family: "IcoFont";
  right: 0;
  transition: all 0.3s ease;
  color: #fff;
  font-size: 18px;
  line-height: 1.8;
  cursor: pointer;
}

.offcanvas-menu .active > .offcanvas-menu-expand:last-child,
.sub-menu > .active .offcanvas-menu-expand:last-child {
  border-bottom: none;
}

.offcanvas-menu .active > .offcanvas-menu-expand::after,
.sub-menu > .active .offcanvas-menu-expand::after {
  color: #fff;
  transform: rotate(90deg);
}

/*Mobile Address*/
.mobile-contact-info .address span {
  display: block;
  color: #fff;
}

.mobile-social-link {
  margin-top: 20px;
  text-align: center;
}
.mobile-social-link a {
  color: #fff;
  margin-right: 30px;
}
.mobile-social-link a:last-child {
  margin-right: 0;
}

.mobile-contact-info {
  display: flex;
  justify-content: center;
}

/************************************
* Layout - Footer
************************************/
.footer-inner-shape-top-left {
  position: absolute;
  width: 800px;
  height: 800px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  top: -475px;
  left: -390px;
  visibility: hidden;
  opacity: 0;
}
@media (min-width: 1200px) {
  .footer-inner-shape-top-left {
    visibility: visible;
    opacity: 1;
    top: -675px;
    left: -590px;
  }
}
@media (min-width: 1400px) {
  .footer-inner-shape-top-left {
    top: -575px;
    left: -490px;
  }
}
@media (min-width: 1800px) {
  .footer-inner-shape-top-left {
    top: -475px;
    left: -390px;
  }
}
.footer-inner-shape-top-left::before {
  position: absolute;
  content: "";
  width: 935px;
  height: 935px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  top: -70px;
  left: -70px;
}
.footer-inner-shape-top-left::after {
  position: absolute;
  content: "";
  width: 1065px;
  height: 1065px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  top: -140px;
  left: -140px;
}

.footer-inner-shape-top-right {
  position: absolute;
  width: 800px;
  height: 800px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  top: -475px;
  right: -390px;
  visibility: hidden;
  opacity: 0;
}
@media (min-width: 1200px) {
  .footer-inner-shape-top-right {
    visibility: visible;
    opacity: 1;
    top: -675px;
    right: -590px;
  }
}
@media (min-width: 1400px) {
  .footer-inner-shape-top-right {
    top: -575px;
    right: -490px;
  }
}
@media (min-width: 1800px) {
  .footer-inner-shape-top-right {
    top: -475px;
    right: -390px;
  }
}
.footer-inner-shape-top-right::before {
  position: absolute;
  content: "";
  width: 935px;
  height: 935px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  top: -70px;
  right: -70px;
}
.footer-inner-shape-top-right::after {
  position: absolute;
  content: "";
  width: 1065px;
  height: 1065px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  top: -140px;
  right: -140px;
}

.footer-newsletter input {
  padding: 15px 30px;
  border-radius: 32.5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  background: transparent;
  margin-right: 0px;
  color: #fff;
}
.footer-newsletter input::placeholder {
  color: #858792;
}
@media (min-width: 576px) {
  .footer-newsletter input {
    margin-right: 20px;
    width: 290px;
  }
}
@media (min-width: 768px) {
  .footer-newsletter input {
    width: 350px;
    padding: 20px 40px;
  }
}
@media (min-width: 992px) {
  .footer-newsletter input {
    width: 500px;
  }
}
@media (min-width: 1200px) {
  .footer-newsletter input {
    width: 615px;
  }
}
.footer-newsletter input:focus {
  border: 1px solid white;
}
.footer-newsletter .submit-btn {
  padding: 15px 30px;
  margin-top: 30px;
  border-radius: 32.5px;
  border: 1px solid #2e303d;
  background: #2e303d;
  color: #fff;
}
@media (min-width: 576px) {
  .footer-newsletter .submit-btn {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .footer-newsletter .submit-btn {
    padding: 20px 40px;
  }
}
.footer-newsletter .submit-btn:hover {
  border-color: #fff;
}

.footer-single-info .info-box .icon {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  margin-right: 20px;
  transition: all 0.5s ease;
}
@media (min-width: 768px) {
  .footer-single-info .info-box .icon {
    width: 55px;
    height: 55px;
    margin-right: 30px;
  }
}
@media (min-width: 992px) {
  .footer-single-info .info-box .icon {
    width: 65px;
    height: 65px;
  }
}
@media (min-width: 1200px) {
  .footer-single-info .info-box .icon {
    width: 85px;
    height: 85px;
  }
}
.footer-single-info .info-box .text {
  display: inline-block;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
}
@media (min-width: 576px) {
  .footer-single-info .info-box .text {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .footer-single-info .info-box .text {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  .footer-single-info .info-box .text {
    font-size: 26px;
  }
}
@media (min-width: 1200px) {
  .footer-single-info .info-box .text {
    font-size: 32px;
  }
}
.footer-single-info .info-box:hover .icon {
  background: #fff;
  border-color: #fff;
  color: #252734;
}

.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 20px;
  padding-bottom: 20px;
}
.footer-bottom .footer-copyright .copyright-text {
  margin-bottom: 0;
  text-align: center;
}
@media (min-width: 768px) {
  .footer-bottom .footer-copyright .copyright-text {
    text-align: left;
  }
}
.footer-bottom .footer-copyright a, .footer-bottom .footer-copyright i {
  color: #fff;
}
.footer-bottom .footer-logo {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  .footer-bottom .footer-logo {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

/**
* material-scrolltop
*
* Author: Bartholomej
* Website: https://github.com/bartholomej/material-scrolltop
* Docs: https://github.com/bartholomej/material-scrolltop
* Repo: https://github.com/bartholomej/material-scrolltop
* Issues: https://github.com/bartholomej/material-scrolltop/issues
*/
.material-scrolltop {
  z-index: 999;
  display: block;
  position: fixed;
  width: 0;
  height: 0;
  bottom: 23px;
  right: 23px;
  padding: 0;
  overflow: hidden;
  outline: none;
  border: none;
  border-radius: 2px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  cursor: hand;
  border-radius: 50%;
  background: #252734;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: all 0.3s cubic-bezier(0.25, 0.25, 0, 1);
}
.material-scrolltop:hover {
  background-color: #252734;
  text-decoration: none;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5), 0 3px 15px rgba(0, 0, 0, 0.5);
}
.material-scrolltop::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 0;
  border-radius: 100%;
  background: #464752;
}
.material-scrolltop:active::before {
  width: 120%;
  padding-top: 120%;
  transition: all 0.2s ease-out;
}
.material-scrolltop.reveal {
  width: 56px;
  height: 56px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.material-scrolltop span {
  display: block;
  font-size: 25px;
  color: #fff;
}

.material-scrolltop, .material-scrolltop::before {
  background-image: url("../../images/icon/top-arrow.svg");
  background-position: center 50%;
  background-repeat: no-repeat;
}