@import "../variables";
@import "../mixins";

.section-bg{
    background: $color-dark-blue;
}
.section-bg-2{
    background: #282a37;
}
.section-mt-75{
    margin-top: 45px;

    @include breakpoint(large){
        margin-top: 65px;
    }
}
.section-mt-165{
    margin-top: 75px;
    @include breakpoint(large){
        margin-top: 115px;
    }
    @include breakpoint(xxlarge){
        margin-top: 135px;
    }
    @include breakpoint(xxxlarge){
        margin-top: 160px;
    }
}

.section-gap-t-165{
    padding-top: 75px;

    @include breakpoint(large){
        padding-top: 115px;
    }
    @include breakpoint(xxlarge){
        padding-top: 135px;
    }
    @include breakpoint(xxxlarge){
        padding-top: 160px;
    }
}
.section-gap-tb-165{
    padding-top: 75px;
    padding-bottom: 75px;

    @include breakpoint(large){
        padding-top: 115px;
        padding-bottom: 115px;
    }
    @include breakpoint(xxlarge){
        padding-top: 135px;
        padding-bottom: 135px;
    }
    @include breakpoint(xxxlarge){
        padding-top: 160px;
        padding-bottom: 160px;
    }
}

.section-content{
    margin-bottom: 40px;

    @include breakpoint(large){
        margin-bottom: 60px;
    }
    @include breakpoint(xxlarge){
        margin-bottom: 100px;
    }
}

.section-tag{
    font-size: 18px;
    font-weight: 300;
    position: relative;
    display: inline-block;
    padding-left: 27px;
   
    &::before{
        position: absolute;
        content: "||";
        top: 0;
        left: 0;
    }
}

.section-title{
    font-size: 36px;
    font-weight: 700;
    margin-top: 25px;
    @include breakpoint(small){
        font-size: 40px;
    }
    @include breakpoint(medium){
        font-size: 50px;
    }
    @include breakpoint(large){
        font-size: 70px;
    }
}