/************************************
* Layout - Footer
************************************/

.footer-inner-shape-top-left{
    position: absolute;
    width: 800px;
    height: 800px;
    border: 1px solid rgba($white, .1);
    border-radius: 50%;
    top: -475px;
    left: -390px;
    visibility: hidden;
    opacity: 0;

    @include breakpoint(xlarge){
        visibility: visible;
        opacity: 1;
        top: -675px;
        left: -590px;
    }
    @include breakpoint(xxlarge){
        top: -575px;
        left: -490px;
    }
    @include breakpoint(xxxlarge){
        top: -475px;
        left: -390px;
    }

    &::before{
        position: absolute;
        content: "";
        width: 935px;
        height: 935px;
        border: 1px solid rgba($white, .1);
        border-radius: 50%;
        top: -70px;
        left: -70px;
    }
    &::after{
        position: absolute;
        content: "";
        width: 1065px;
        height: 1065px;
        border: 1px solid rgba($white, .1);
        border-radius: 50%;
        top: -140px;
        left: -140px;
    }
}
.footer-inner-shape-top-right{
    position: absolute;
    width: 800px;
    height: 800px;
    border: 1px solid rgba($white, .1);
    border-radius: 50%;
    top: -475px;
    right: -390px;
    visibility: hidden;
    opacity: 0;

    @include breakpoint(xlarge){
        visibility: visible;
        opacity: 1;
        top: -675px;
        right: -590px;
    }
    @include breakpoint(xxlarge){
        top: -575px;
        right: -490px;
    }
    @include breakpoint(xxxlarge){
        top: -475px;
        right: -390px;
    }

    &::before{
        position: absolute;
        content: "";
        width: 935px;
        height: 935px;
        border: 1px solid rgba($white, .1);
        border-radius: 50%;
        top: -70px;
        right: -70px;
    }
    &::after{
        position: absolute;
        content: "";
        width: 1065px;
        height: 1065px;
        border: 1px solid rgba($white, .1);
        border-radius: 50%;
        top: -140px;
        right: -140px;
    }
}

.footer-newsletter{
    
    input{
       padding: 15px 30px;
       border-radius: 32.5px;
       border: 1px solid rgba($white, .1);
       width: 100%;
       background: transparent;
       margin-right: 0px;
       color: $white;
       &::placeholder{
           color: #858792;
       }
       @include breakpoint(small){
        margin-right: 20px;
        width: 290px;
       }
       @include breakpoint(medium){
          width: 350px;
          padding: 20px 40px;
       }
       @include breakpoint(large){
          width: 500px;
       }
       @include breakpoint(xlarge){
          width: 615px;
       }
       &:focus{
        border: 1px solid rgba($white, 1);
       }
       
    }

    .submit-btn{
        padding: 15px 30px;
        margin-top: 30px;
        border-radius: 32.5px;
        border: 1px solid #2e303d;
        background: #2e303d;
        color: $white;
        @include breakpoint(small){
            margin-top: 0;
        }
        @include breakpoint(medium){
            padding: 20px 40px;
        }
        &:hover{
            border-color: $white;
        }
    }
}

.subscribe-btn{
    padding: 15px 30px;
    margin-top: 30px;
    border-radius: 32.5px;
    border: 1px solid #2e303d;
    background: #2e303d;
    color: $white;
    @include breakpoint(small){
        margin-top: 0;
    }
    @include breakpoint(medium){
        padding: 20px 40px;
    }
    &:hover{
        border-color: $white;
    }
}

.footer-single-info{
    .info-box{
        .icon{
            height: 45px;
            width: 45px;
            border-radius: 50%;
            border: 1px solid rgba($white, .1);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            color: $white;
            margin-right: 20px;
            transition: $transition-base;
            @include breakpoint(medium){
                width: 55px;
                height: 55px;
                margin-right: 30px;
            }
            @include breakpoint(large){
                width: 65px;
                height: 65px;
            }
            @include breakpoint(xlarge){
                width: 85px;
                height: 85px;
            }

        }

        .text{
            display: inline-block;
            color: $white;
            font-size: 18px;
            font-weight: 300;
            @include breakpoint(small){
                font-size: 20px;
            }
            @include breakpoint(medium){
                font-size: 22px;
            }
            @include breakpoint(large){
                font-size: 26px;
            }
            @include breakpoint(xlarge){
                font-size: 32px;
            }
        }

        &:hover{
            .icon{
                background: $white;
                border-color: $white;
                color: $color-dark-blue;
            }
        }
    }
}

.footer-bottom{
    border-top: 1px solid rgba($white, .1);
    padding-top: 20px;
    padding-bottom: 20px;

    .footer-copyright{
        
        .copyright-text{
            margin-bottom: 0;
            text-align: center;

            @include breakpoint(medium){
                text-align: left;
            }
        }
        a, i{
            color: $white;
        }
    }

    .footer-logo{
        display: inline-block;
        padding-top: 10px;
        padding-bottom: 10px;
        @include breakpoint(medium){
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }
}

