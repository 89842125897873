/*****************************
* Base - Default CSS
*****************************/

/*-- Google Font --*/
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Font Family

font-family: 'Cormorant Garamond', serif;
font-family: 'Poppins', sans-serif;

*/


@import "./variables";
@import "./mixins";


/*-- Common Style --*/
html {
	scroll-behavior: smooth;
}
html,
body {
    height: 100%;
}
body{
    background: $color-gray-blue;
    color: $body-color;
    font-family: $body-font;
}
a{
    color: $body-color;
    text-decoration: none;
    &:hover {
        color: $white;
    }
}
a, 
button, 
.btn,
img, 
input {
    transition: $transition-base;
}
a,
a:hover,
a:focus,
a:active,
button,
button:focus,
.btn,
.btn:focus,
input,
input:focus,
select,
textarea,
.swiper-pagination-bullet,
.swiper-slide,
.append-button,
.swiper-button-next,
.swiper-button-prev {
	text-decoration: none;
    outline: 0;
    box-shadow: none;
}
.btn{
    &:active:focus{
        box-shadow: none;
    }
    &-primary{
        &:focus{
            border-color: $primary;
            color: $white;
        }
    }
}
.accordion-button:focus{
    box-shadow: none;
}
h1, h2, h3, h4, h5, h6{
    color: $white;
    font-family: $heading-font;
}
ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
img {
	max-width: 100%;
}

input, textarea{
    width: 100%;
}

p{
    &:last-child{
        margin-bottom: 0;
    }
}

.img-full {
	width: 100%;
	overflow: hidden;
	object-fit: cover;
	object-position: center center;
}
::selection{
    background-color: $primary;
    color: $white;
}

.section-dark-blue-bg{
    background: $color-dark-blue;
}
.section-gray-blue-bg{
    background: $color-gray-blue;
}

.social-link{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;
    li{
        margin-right: 20px;
        margin-bottom: 20px;
        &:last-child{
            margin-right: 0;
        }
    }

    a{
        display: block;
        color: $white;
        height: 45px;
        width: 45px;
        border-radius: 50%;
        border: 1px solid rgba($white, .1);
        line-height: 45px;
        text-align: center;
        &:hover{
            background: $white;
            border-color: $white;
            color: #252734;
        }

        @include breakpoint(medium){
            height: 60px;
            width: 60px;
            line-height: 60px
        }
    }
}

@import "common/typhography";
@import "common/button";
@import "common/measuremant";
@import "common/section";
@import "common/form";

