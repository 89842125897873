/************************************
* Component - Company Logo Display
************************************/

.company-logo-single-item{
    border: 1px solid #353743;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .image{
        width: 142px;
        height: 142px;
        display: block;
        text-align: center;
        position: relative;
        img{
            width: 100%;
            &:nth-child(1){
                opacity: 1;
            }
            &:nth-child(2){
                position: absolute;
                opacity: 0;
                top: 0;
                left: 0;
            }
        }
    }

    &:hover{
        border-color: $color-dark-blue;
        background: $color-dark-blue;

        .image{
            img{
                &:nth-child(1){
                    opacity: 0;
                }
                &:nth-child(2){
                    opacity: 1;
                }
            }
        }
    }


}