.blog-list-single-item{
    overflow: hidden;
    position: relative;
    padding: 35px;
    background: $color-dark-blue;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;
    &:last-child{
        margin-bottom: 0;
    }
    @include breakpoint(medium){
        align-items: center;
        flex-direction: row;
    }
    @include breakpoint(large){
        align-items: flex-start;
        padding: 30px;
    }
    @include breakpoint(xlarge){
        align-items: center;
        padding: 50px;
    }
    .image{
        width: 100%;
        margin-right: 0px;
        @include breakpoint(medium){
            width: 280px;
            height: auto;
            margin-right: 30px;
        }
        @include breakpoint(large){
            width: 130px;
            margin-right: 30px;
        }
        @include breakpoint(xlarge){
            width: 180px;
            margin-right: 70px;
        }
        img{
            width: 100%;
            object-fit: cover;
        }
    }

    .content{
      margin-top: 40px;
        @include breakpoint(medium){
            margin-top: 0px;
        }
        @include breakpoint(large){
            margin-top: 0;
            width: calc(100% - 170px);
        }
        @include breakpoint(xlarge){
            margin-top: 0;
            width: calc(100% - 250px);
        }

        .title{
            margin-top: 23px;
            line-height: 36px;
            font-size: 22px;
            font-family: $body-font;
            font-weight: 500;

            @include breakpoint(small){
                line-height: 42px;
                font-size: 24px;
            }
            @include breakpoint(large){
                margin-top: 14px;
                line-height: 30px;
                font-size: 20px;
            }
            @include breakpoint(xlarge){
                margin-top: 23px;
                line-height: 42px;
                font-size: 25px;
            }
            a{
                color: $white;
            }
        }
    }

    .post-meta-2{
        margin-top: 15px;
    }

    &:hover .inner-shape{
        transform: scale(1);
        visibility: visible;
        opacity: 1;
    }
}

