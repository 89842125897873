//=======================
// Developer Variable
//=======================

// Font Family
$cormorantGaramond-font: 'Cormorant Garamond', serif;
$poppins-font: 'Poppins', sans-serif;

$heading-font: $cormorantGaramond-font;
$body-font: $poppins-font;


// Custom Color
$body-text-color: #858792;

$color-dark-blue: #252734;
$color-gray-blue: #2a2c39;

$color-primary: $color-dark-blue;
$color-secondary: $color-gray-blue;

$color-light-white: #fffefe;
$color-gray-white: #f8f5f3;

$white:    #fff;
$blue:    #0d6efd;
$gray-900: #212529;
$black:    #000;
$primary:       $blue;
$dark:          $gray-900;


$body-color: $body-text-color;
$transition-base: all .5s ease;