.solution-hero-image{
    margin-bottom: 65px;
    height: 350px;
    overflow: hidden;

    @include breakpoint(medium){
        height: 400px;
    }
    @include breakpoint(large){
        height: 500px;
    }
    @include breakpoint(xlarge){
        height: 600px;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.solution-sidebar{
    background: $color-dark-blue;
    padding: 60px 40px 75px 40px;
    margin-top: 60px;
    @include breakpoint(large){
        position: absolute;
        top: calc(0% + 420px);
        right: 15px;
        margin-top: 0;
    }
    @include breakpoint(xlarge){
        top: calc(0% + 560px);
    }
    .title{
        font-size: 30px;
        color: $white;
        font-weight: 600;
        margin-bottom: 45px;

        @include breakpoint(medium){
            font-size: 32px;
        }
        @include breakpoint(large){
            font-size: 34px;
        }
        @include breakpoint(xlarge){
            font-size: 38px;
        }
    }

    .solution-sidebar-single-box{
        padding: 25px 30px 25px 30px;
        border: 1px solid $color-gray-blue;
        background: $color-gray-blue;
        margin-bottom: 10px;
        transition: $transition-base;
        &:last-child{
            margin-bottom: 0;
        }

        .title-text{
            font-size: 15px;
            color: $white;
            font-family: $body-font;
            font-weight: 500;
            margin-bottom: 20px;
        }

        &:hover{
            background: $color-dark-blue;
            border-color: rgba($white, .1);
        }
    }
}