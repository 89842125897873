/************************************
* Layout - Header
************************************/

.header-section{
    position: absolute;
    width: 100%;
    z-index: 999;
    padding-top: 15px;
    padding-bottom: 15px;
}

.header-logo{
    // z-index: 999;
    display: block;
}

.header-nav{
    display: flex;
    
    & > li{
        
        @include breakpoint(large){
            padding-left: 15px;
            padding-right: 15px;
        }
        @include breakpoint(xlarge){
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    & > li > a{
        font-size: 18px;
        line-height: 120px;
        display: inline-block;
        color: $body-color;
        position: relative;
        
        &:hover{
            color: $white;
        }

        &::after{
            position: absolute;
            content: "";
            top: 50%;
            transform: translate(-50%, -50%) scale(.9);
            left: auto;
            left: 50%;
            width: 120px;
            height: 60px;
            background-image: url("../../../images/icon/menu-hover-shape.png");
            visibility: hidden;
            opacity: 0;
            transition: $transition-base;
        }

        &:hover::after{
            transform: translate(-50%, -50%) scale(1);
            visibility: visible;
            opacity: 1;
        }
        
    }

    .has-dropdown{
        position:  relative;
        
        &:hover .submenu{
            visibility: visible;
            opacity: 1;
            transform: translateY(0) scaleY(1);
        }
    }

    .submenu{
        position: absolute;
        top: 100%;
        left: 0%;
        width: 280px;
        background: $color-secondary;
        border-radius: 0 0 5px 5px;
        visibility: hidden;
        opacity: 0;
        transition: transform 0.1s cubic-bezier(0.2, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
        transform: translateY(0px) scaleY(0.2);
        transform-origin: top;
        transition: $transition-base;
        z-index: 9;
        a{
            padding: 10px 25px;
            display: block;
            position: relative;
            color: $white;
            z-index: 1;

            &::after{
                position: absolute;
                content: "";
                top: 0;
                left: auto;
                right: 0;
                width: 0%;
                height: 100%;
                background: $white;
                transition: $transition-base;
                z-index: -1;
            }

            &:hover{
                color: $color-dark-blue;
                &::after{
                    left: 0;
                    width: 100%;
                }
            }
        }
    }
}


/*Main Menu Style*/
.header-section.sticky-header.sticky {
    padding-top: 0;
    padding-bottom: 0;
}
.sticky-header.sticky {
    position: fixed;
    z-index: 99;
    width: 100%;
    top: 0;
    background: $color-primary;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    animation-name: fadeInDown;
    animation-duration: 900ms;
    animation-timing-function: cubic-bezier(0.2, 1, 0.22, 1);
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    border-bottom: 0;
}


.header-btn-link{
    .btn{
        font-size: 18px;
    }
}

@import "mobile-menu/mobile-header";
@import "mobile-menu/offcanvas-sidebar";
@import "mobile-menu/offcanvas-menu";